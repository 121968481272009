import { AnatomicalAxis, AnatomicalSideEnum, Apex, ApexSign, BoneTypeEnum, MechanicalAxisFemurAP, PlanTypeEnum, ReferenceTypeEnum, SelectedApexMech, Utils, VectorUtils, ViewType } from '@ortho-next/nextray-core';
import { MathUtils, Matrix4, Vector3 } from '@ortho-next/three-base/three.js/build/three.module';
import { AngularDeformityTypeEnum, TranslationTypeEnum } from 'src/app/core/repositories/models/deformity-parameters';
import { ToolsAP, ToolsLT } from '../Core/Tools';
import { bindedModel } from '../Models/BindedModel';
import { OsteotomyCut, StateTypes } from '../States/State';
import { MechanicalAxisAP } from '../Tools/DeformityAnalyzer/FullAnalyzerAP';
import { MechanicalAxisLT } from '../Tools/DeformityAnalyzer/FullAnalyzerLT';
import { EoCPlane } from '../Tools/EoCPlane/EoCPlane';
import { Osteotomy } from '../Tools/Osteotomy/Osteotomy';
import { OsteotomyAP } from '../Tools/Osteotomy/OsteotomyAP';
import { OsteotomyAnatomical } from '../Tools/Osteotomy/OsteotomyAnatomical';
import { Consts } from './Consts';

/**
 * Contains formulas for calculating measurements using multiple tools.
 */
export class MeasuresUtils {

	/**
	 * Calculates the horizontal translation that will be applied after correction.
	 */
	public static getHorizontalTranslation(ost: Osteotomy, mechAxis: MechanicalAxisAP | MechanicalAxisLT, rotationCenter: Vector3): number {
		const isTibia = ost.selectedApex === SelectedApexMech.tibiaProximal || ost.selectedApex === SelectedApexMech.tibiaDistal;
		if (mechAxis instanceof MechanicalAxisLT && !isTibia) {
			return 0;
		}
		const mechSide = isTibia ? mechAxis.tibia : mechAxis.femur as MechanicalAxisFemurAP;
		const angle = mechSide.apexWithSign;
		const apex = mechSide instanceof MechanicalAxisFemurAP ? mechSide.mechanical.apex.position : mechSide.apex.position;
		const bisector = mechSide.apexBisector;
		const apexAngle = mechSide.apexAngleToFix.value;
		let distanceRotCenterFromBisector;

		if (apexAngle <= Math.PI / 2) {
			const projectedRotationCenter = VectorUtils.projectOnVector(rotationCenter, apex, apex.clone().add(bisector));
			distanceRotCenterFromBisector = projectedRotationCenter.distanceTo(rotationCenter);
		} else { //apex out of joints
			const projectedRotationCenter = VectorUtils.projectOnVector(rotationCenter, apex, apex.clone().add(bisector));
			distanceRotCenterFromBisector = projectedRotationCenter.distanceTo(apex);
		}


		// Calculate sign
		let sign = 1;
		if (apexAngle <= Math.PI / 2) { // apex inside joints
			const isUpperBisector = VectorUtils.arePointsOnSameSide(apex, apex.clone().add(bisector), Consts.planeNormal, apex.clone().add(Consts.verDir), rotationCenter);
			sign = isUpperBisector ? -1 : 1;
		} else { // apex ouside joints
			const isUpperApex = VectorUtils.arePointsOnSameSide(apex, apex.clone().add(Consts.horDir), Consts.planeNormal, apex.clone().add(Consts.verDir), rotationCenter);
			sign = isUpperApex ? -1 : 1;
		}

		return distanceRotCenterFromBisector * Math.tan((angle) / -2) * 2 * sign;
	}

	/**
	 * Calculates the EOC vertical translation tha will be applied in both views.
	 */
	public static getEoCVerticalTranslationBeforeCut(toolsAP: ToolsAP, toolsLT: ToolsLT, cutType: OsteotomyCut): number {
		const osteotomyLength = Math.max(toolsAP.osteotomy ? toolsAP.osteotomy.length : 0, toolsLT.osteotomy ? toolsLT.osteotomy.length : 0);
		const angleAP = (toolsAP.mechanicalAxis && toolsAP.osteotomy) ? toolsAP.mechanicalAxis.getApexWithSign(toolsAP.osteotomy.selectedApex as SelectedApexMech) : 0;
		const angleLT = (toolsLT.mechanicalAxis && toolsLT.osteotomy) ? toolsLT.mechanicalAxis.getApexWithSign(toolsLT.osteotomy.selectedApex as SelectedApexMech) : 0;
		const sign = cutType == OsteotomyCut.closing ? -1 : 1;
		return Math.sin(Math.atan(Math.sqrt(Math.tan(angleAP) ** 2 + Math.tan(angleLT) ** 2))) * osteotomyLength / 2 * sign;
	}

	/**
	 * Calculates the cortex wire used in report to print only.
	 */
	public static getCortexWire(mechanicalAxis: MechanicalAxisAP, osteotomy: OsteotomyAP, selectedApex: SelectedApexMech, wireOrigin: Vector3): number {
		if (selectedApex == SelectedApexMech.femurProximal || selectedApex == SelectedApexMech.femurDistal) {
			const refPoint = this.getReferencePointToProximalPrintMeasure(mechanicalAxis, osteotomy);
			return refPoint.distanceTo(wireOrigin.setZ(0));
		}
	}

	/**
	* Calculates the osteotomy level used in report to print only.
	*/
	public static getOsteotomyLevel(mechanicalAxis: MechanicalAxisAP, osteotomy: OsteotomyAP, selectedApex: SelectedApexMech): number {
		switch (selectedApex) {
			case SelectedApexMech.femurProximal: {
				const refPoint = this.getReferencePointToProximalPrintMeasure(mechanicalAxis, osteotomy);
				return refPoint.distanceTo(mechanicalAxis.femur.mechanical.GT.position);
			}
			case SelectedApexMech.femurDistal: {
				const refPoint = this.getReferencePointToProximalPrintMeasure(mechanicalAxis, osteotomy);
				return refPoint.distanceTo(mechanicalAxis.femur.mechanical.LE.position);
			}
			case SelectedApexMech.tibiaProximal:
				return mechanicalAxis.tibia.CP.position.distanceTo(osteotomy.C.position);
			case SelectedApexMech.tibiaDistal:
				return mechanicalAxis.tibia.CA.position.distanceTo(osteotomy.C.position);
		}
	}

	/**
	 * Calculates the wire insertion angle used in report to print only.
	 */
	public static getWireInsertionAngle(mechanicalAxis: MechanicalAxisAP, EOCplane: EoCPlane, wireAngleDegrees: number, selectedApex: SelectedApexMech): number {
		if (selectedApex == SelectedApexMech.femurProximal || selectedApex == SelectedApexMech.femurDistal) {
			const wedge = EOCplane.angle;
			const sign = mechanicalAxis.femur.apexAngleSign.value === ApexSign.valgus ? 1 : -1;
			return MathUtils.degToRad(wireAngleDegrees) + (wedge * sign);
		}
	}

	private static getReferencePointToProximalPrintMeasure(mechanicalAxis: MechanicalAxisAP, osteotomy: OsteotomyAP): Vector3 {
		const sideAxis = Consts.horDir.clone().multiplyScalar(mechanicalAxis.isLeft ? 1 : -1).applyAxisAngle(Consts.planeNormal, mechanicalAxis.femur.angleForTemplating);
		const Asign = VectorUtils.computeSign(osteotomy.A.position, osteotomy.C.position, sideAxis);
		return Asign === 1 ? osteotomy.A.position : osteotomy.B.position;
	}

	private static getAnatomicalTranslationDeformity(osteotomy: OsteotomyAnatomical, moving: AnatomicalAxis, isPostOp: boolean) {
		if (
			!osteotomy?.apex ||
			(bindedModel.appState === StateTypes.deformityAnalysis && !isPostOp) ||
			(moving === osteotomy.apex.distalAxis && Math.round(MathUtils.radToDeg(osteotomy.apex.angularDeformity.value)) === 0)
		) //if no eoc
			return 0;

		const reference = osteotomy.reference;
		const refAB = reference.AB;
		const originForSignCalculation = osteotomy.apexPos;
		const refABPerp = VectorUtils.getPerpendicular(refAB);
		const drivingSegment = osteotomy.isOnReferenceSide ? reference : moving;
		const oppositeSegment = drivingSegment === reference ? moving : reference;

		const intDsOst = VectorUtils.lines2DIntersection(drivingSegment.A.position, drivingSegment.B.position, osteotomy.A.position, osteotomy.B.position);
		if (!intDsOst) return 0;
		const intPointRefPerp = VectorUtils.lines2DIntersection(oppositeSegment.A.position, oppositeSegment.B.position, intDsOst, intDsOst.clone().add(refABPerp));
		if (!intPointRefPerp) return 0;
		const distance = intDsOst.distanceTo(intPointRefPerp);
		const sign = -Math.sign(refAB.dot(intDsOst.clone().sub(originForSignCalculation))); //todo use compute sign if clean code

		return distance * Math.sign(osteotomy.apex.angularDeformity.value) * sign;
	}

	private static getAnatomicalTranslationFracture(apex: Apex, side: AnatomicalSideEnum, boneType: BoneTypeEnum, viewType: ViewType, movingB: Vector3): number {
		const reference = apex.proximalAxis as AnatomicalAxis; //todo fix type
		const refPerp = VectorUtils.getPerpendicularWithDirectionBasedOnPoint(reference.A.position, reference.B.position, reference.A1.position);
		const int = VectorUtils.lines2DIntersection(movingB, movingB.clone().add(refPerp), reference.A.position, reference.B.position); //could be projected
		const sign = (side === AnatomicalSideEnum.Left ? 1 : -1) * VectorUtils.computeSign(int, movingB, refPerp);
		const signFoot = Utils.isFoot(boneType) && viewType === ViewType.LT && side === AnatomicalSideEnum.Right ? -1 : 1;
		return movingB.distanceTo(int) * sign * signFoot;
	}

	private static getAnatomicalAxialTranslationFracture(apex: Apex, moving: AnatomicalAxis): number {
		const reference = apex.proximalAxis as AnatomicalAxis; //fix type
		const refDir = reference.B.position.clone().sub(reference.A.position);
		const refPerp = VectorUtils.getPerpendicular(refDir);
		const int = VectorUtils.lines2DIntersection(moving.B.position, moving.B.position.clone().add(refDir), reference.B.position, reference.B.position.clone().add(refPerp));
		const sign = VectorUtils.computeSign(moving.B.position, reference.B.position, refDir);
		return moving.B.position.distanceTo(int) * sign;
	}

	public static getAnatomicalAxialTranslation(apex: Apex, planeType: PlanTypeEnum): number {
		return planeType === PlanTypeEnum.Fracture ?
			this.getAnatomicalAxialTranslationFracture(apex, apex.distalAxis as AnatomicalAxis) : 0;
	}

	public static getAnatomicalDefTranslation(apex: Apex, osteotomy: OsteotomyAnatomical, side: AnatomicalSideEnum, planeType: PlanTypeEnum, boneType: BoneTypeEnum, viewType: ViewType, isPostOp: boolean): number {
		return planeType == PlanTypeEnum.Fracture ?
			this.getAnatomicalTranslationFracture(apex, side, boneType, viewType, (apex.distalAxis as AnatomicalAxis).B.position) :
			this.getAnatomicalTranslationDeformity(osteotomy, osteotomy?.moving, isPostOp);
	}

	public static calculateSuggestedBoneLength(planeType: PlanTypeEnum, refType: ReferenceTypeEnum, ost: OsteotomyAnatomical, bernsteinTolerance = 5): number {
		if (planeType == PlanTypeEnum.Fracture || !ost?.apex) return 0;

		//BERNSTEIN PART
		const reference = ost.anatomicalAxis.reference as AnatomicalAxis;
		const moving = ost.anatomicalAxis.moving as AnatomicalAxis;
		const apexPos = ost.apexPos;
		const BAref = reference.B.position.clone().sub(reference.A.position);
		const BAmov = moving.B.position.clone().sub(moving.A.position);
		const BArefPerp = VectorUtils.getPerpendicular(BAref);
		const BAmovPerp = VectorUtils.getPerpendicular(BAmov);
		const startPointOnRefForBernstein = apexPos.clone().add(BAref.clone().setLength(bernsteinTolerance));
		const startPointOnMovForBernstein = apexPos.clone().add(BAmov.clone().setLength(bernsteinTolerance));
		const intersectionBernsteinPoints = VectorUtils.lines2DIntersection(startPointOnRefForBernstein, startPointOnRefForBernstein.clone().add(BArefPerp), startPointOnMovForBernstein, startPointOnMovForBernstein.clone().add(BAmovPerp));

		let modelDir: Vector3;
		if (intersectionBernsteinPoints) {
			const drivingSegment = ost.isOnReferenceSide ? reference : moving;
			const intersectionSegOst = VectorUtils.lines2DIntersection(ost.A.position, ost.B.position, drivingSegment.A.position, drivingSegment.B.position);
			if (intersectionSegOst && apexPos.distanceTo(intersectionSegOst) < bernsteinTolerance) {
				modelDir = VectorUtils.getPerpendicular(intersectionBernsteinPoints.clone().sub(intersectionSegOst)).multiplyScalar(-1).normalize();
			} else {
				modelDir = drivingSegment.A.position.clone().sub(drivingSegment.B.position).normalize();
			}
		} else {
			modelDir = BAref;
		}
		//END BERNSTEIN PART

		let impingementA = 0, impingementB = 0;
		const rotationMatrix = new Matrix4();
		const angle = ost.apex.angularDeformity.value * (refType === ReferenceTypeEnum.Proximal ? -1 : 1);
		rotationMatrix.makeRotationZ(-angle);
		rotationMatrix.setPosition(apexPos);

		const rotatedOstA = ost.A.position.clone().sub(apexPos).applyMatrix4(rotationMatrix);
		const rotatedOstB = ost.B.position.clone().sub(apexPos).applyMatrix4(rotationMatrix);

		if (VectorUtils.arePointsOnSameSide(ost.A.position, ost.B.position, Consts.planeNormal, rotatedOstA, ost.A.position.clone().sub(BAref))) {
			const int = VectorUtils.lines2DIntersection(rotatedOstA, rotatedOstA.clone().add(modelDir), ost.A.position, ost.B.position);
			int && (impingementA = int.distanceTo(rotatedOstA));
		}

		if (VectorUtils.arePointsOnSameSide(ost.A.position, ost.B.position, Consts.planeNormal, rotatedOstB, ost.A.position.clone().sub(BAref))) {
			const int = VectorUtils.lines2DIntersection(rotatedOstB, rotatedOstB.clone().add(modelDir), ost.A.position, ost.B.position);
			int && (impingementB = int.distanceTo(rotatedOstB));
		}

		return Math.max(1, Math.ceil(impingementA), Math.ceil(impingementB));
	}

	public static getAngleSign(value: AngularDeformityTypeEnum, side: AnatomicalSideEnum, refType: ReferenceTypeEnum): number {
		let sign = side === AnatomicalSideEnum.Left ? 1 : -1;
		sign *= (refType === ReferenceTypeEnum.Proximal ? 1 : -1);
		switch (value) {
			case AngularDeformityTypeEnum.Valgus:
			case AngularDeformityTypeEnum.ApexPosterior:
				return sign;
			case AngularDeformityTypeEnum.Varus:
			case AngularDeformityTypeEnum.ApexAnterior:
				return -sign;
		}
		return 0;
	}

	public static getTranslSign(value: TranslationTypeEnum, side: AnatomicalSideEnum): number {
		let sign = side === AnatomicalSideEnum.Left ? 1 : -1;
		switch (value) {
			case TranslationTypeEnum.Lateral:
			case TranslationTypeEnum.Anterior:
				return sign;
			case TranslationTypeEnum.Medial:
			case TranslationTypeEnum.Posterior:
				return -sign;
		}
		return 0;
	}

	public static getAxTranslSign(value: TranslationTypeEnum): number {
		switch (value) {
			case TranslationTypeEnum.Long:
				return 1;
			case TranslationTypeEnum.Short:
				return -1;
		}
		return 0;
	}

	public static getAngleSignEoC(value: AngularDeformityTypeEnum): number {
		switch (value) {
			case AngularDeformityTypeEnum.Valgus:
			case AngularDeformityTypeEnum.ApexPosterior:
				return -1;
			case AngularDeformityTypeEnum.Varus:
			case AngularDeformityTypeEnum.ApexAnterior:
				return 1;
		}
		return 0;
	}

	public static getTranslSignEoC(value: TranslationTypeEnum): number {
		switch (value) {
			case TranslationTypeEnum.Lateral:
			case TranslationTypeEnum.Anterior:
			case TranslationTypeEnum.Long:
				return 1;
			case TranslationTypeEnum.Medial:
			case TranslationTypeEnum.Posterior:
			case TranslationTypeEnum.Short:
				return -1;
		}
		return 0;
	}

}
