import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@ortho-next/auth-core';
import { SseService } from '@ortho-next/sse';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { isGuid, LanguageService, MonitoringService } from './core';
import { BaseComponent } from './shared';


/**
* Root application component
*/
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private langSrv: LanguageService,
		private monitoringService: MonitoringService,
		private titleService: Title,
		private sse: SseService
	) {
		super(langSrv);
		authService
			.isDoneLoading$.subscribe(() => {
				if (authService.hasValidToken()) {
					sse.connection(environment.sseAPI, () => firstValueFrom(authService.getAccessToken()));

					const userName = authService.userName;
					if (userName) {
						monitoringService.setAuthenticatedUserId(userName);
					}
				}
			});
		authService
			.onLogout$.subscribe(() => monitoringService.clearAuthenticatedUserId());

		authService
			.runInitialLoginSequence()
			.then(() => {
				const urlParams = new URLSearchParams(window.location.search);
				const caseGuid = urlParams.get('caseGuid');
				if (!isGuid(caseGuid)) {
					window.location.href = environment.patplanSite;
				}
			});
	}

	ngOnInit() {
		this.titleService.setTitle(this.labels.APP_MAIN_TITLE);
	}

}
