import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';


/**
 * This service handles lables and validation messages.
 */
@Injectable()
export class LanguageService {

	private _labels: any = {};

	constructor(private http: HttpClient) { }

	/**
	* Get labels
	*/
	getLabels(): any {
		return this._labels;
	}

	/**
	* Get label
	*/
	getLabel(id: string): string {
		return this._labels[id];
	}

	/**
	* Load labels
	*/
	loadLabels(): Promise<any> {
		return firstValueFrom(this.http.get("assets/labels.json").pipe(tap(res => this._labels = res)));
	}

	/**
	* Get validation messages
	*/
	getValidationMessages(): Observable<any> {
		return this.http.get("assets/validation-messages.json");
	}
}
