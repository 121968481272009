import { Component } from '@angular/core';
import { AxialRotationEnum, NextRayMeasuresDeformityAP, NextRayMeasuresDeformityLT, ViewStateType, ViewType } from '@ortho-next/nextray-core/Models/AppModel';
import { SelectedApexAnat, SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { LayerElements, NextrayMeasuresAnatomicalApex, NextrayMeasuresEoCAnat, NextrayMeasuresEoCMech, PlateOrientationType } from '../../../nextray/Models/AppModel';
import { AxialTranslationEnum, DeformityAnalysisType } from '../../../nextray/Models/Enums';
import { OsteotomyCut, StateDescription, StateTypes, WorkflowRunning } from '../../../nextray/States/State';
import { AnatomicalSideEnum, BoneTypeEnum, LanguageService, Plate } from '../../core';
import { CanvasService } from '../../services';

/**
* Base component to share common features in every component
*/
@Component({
	selector: 'base-component',
	templateUrl: './base.component.html'
})
export class BaseComponent {

	private _labels: any = {};

	constructor(private languageSrv: LanguageService, private _canvasSrv?: CanvasService) {
		this._labels = languageSrv.getLabels();
	}

	// LANGUAGE DATA //

	/**
	* Get labels
	*/
	get labels() {
		return this._labels;
	}


	// CANVAS ENUM //

	workflowEnum = WorkflowRunning;
	activeViewEnum = ViewType;
	activeViewStateEnum = ViewStateType;
	stateTypeEnum = StateTypes;
	defTypeEnum = DeformityAnalysisType;

	sideEnum = AnatomicalSideEnum;


	// CANVAS DATA //

	/**
	* Get mobile device flag.
	*/
	get isMobile(): boolean {
		return this._canvasSrv.isMobile;
	}

	/**
	* Check if current case has AP image
	*/
	get hasAP(): boolean {
		return this._canvasSrv.hasAP;
	}

	/**
	* Check if current case has Lateral image
	*/
	get hasLT(): boolean {
		return this._canvasSrv.hasLT;
	}

	/**
	* Check if current case has Long Leg as bone type
	*/
	get isLongLeg(): boolean {
		return this._canvasSrv.isLongLeg;
	}

	/**
	* Check if current case has Femur as bone type
	*/
	get isFemur(): boolean {
		return this._canvasSrv.isFemur;
	}

	/**
	* Check if current case has Tibia as bone type
	*/
	get isTibia(): boolean {
		return this._canvasSrv.isTibia;
	}

	/**
	* Check if current case has Ankle bone type.
	*/
	get isAnkle(): boolean {
		return this._canvasSrv.isAnkle;
	}

	/**
	* Check if current case has ForeFoot bone type.
	*/
	get isForeFoot(): boolean {
		return this._canvasSrv.isForeFoot;
	}

	/**
	* Check if current case has HindFoot bone type.
	*/
	get isHindFoot(): boolean {
		return this._canvasSrv.isHindFoot;
	}

	/**
	* Check if current case has a Leg bone type.
	*/
	get isLegBone(): boolean {
		return this._canvasSrv.isLegBone;
	}

	/**
	* Check if current case has a Foot bone type.
	*/
	get isFootBone(): boolean {
		return this._canvasSrv.isFootBone;
	}

	/**
	* Check if current case has left as bone side
	*/
	get isLeft(): boolean {
		return this._canvasSrv.isLeft;
	}

	/**
	* Check if current case has right as bone side
	*/
	get isRight(): boolean {
		return this._canvasSrv.isRight;
	}

	/**
	* Check if current case has Deformity plan type.
	*/
	get isDeformity(): boolean {
		return this._canvasSrv.isDeformity;
	}

	/**
	* Check if current case has Fracture plan type.
	*/
	get isFracture(): boolean {
		return this._canvasSrv.isFracture;
	}

	/**
	* Check if current case is post operative.
	*/
	get isPostOp(): boolean {
		return this._canvasSrv.currentCase?.isPostOperative;
	}

	/**
	* Get current state description
	*/
	get stateDescription(): StateDescription {
		return this._canvasSrv ? this._canvasSrv.stateDescription : null;
	}

	/**
	 * Check if mechanical/contralateral axis workflow is active.
	 */
	get isMechAxisWorkflow(): boolean {
		return this.stateDescription?.isWorkflowRunning == WorkflowRunning.insertMechanicalAxis || this.stateDescription?.isWorkflowRunning == WorkflowRunning.insertContralateral;
	}

	/**
	* Get current state type
	*/
	get stateType(): StateTypes {
		return this._canvasSrv ? this._canvasSrv.stateType : null;
	}

	/**
	* Get current state view, i.e AP, Lateral or Multiple
	*/
	get activeViewState(): ViewStateType {
		return this._canvasSrv ? this._canvasSrv.activeViewState : null;
	}

	/**
	* Get current active view, i.e AP or Lateral view
	*/
	get activeView(): ViewType {
		return this._canvasSrv ? this._canvasSrv.activeView : null;
	}

	/**
	* Get current deformity measures of mechanical axis in AP View.
	*/
	get defAPMeasures(): NextRayMeasuresDeformityAP {
		return this._canvasSrv.defAPMeasures;
	}

	/**
	* Get current deformity measures of mechanical axis in Lateral View.
	*/
	get defLTMeasures(): NextRayMeasuresDeformityLT {
		return this._canvasSrv.defLTMeasures;
	}

	/**
	* Get current measures of proximal apex in AP View.
	*/
	get anatApexProxAP(): NextrayMeasuresAnatomicalApex {
		return this.isAnatomicalAxisInserted ? this._canvasSrv.anatApexProxAP : {};
	}

	/**
	* Get current measures of distal apex in AP View.
	*/
	get anatApexDistAP(): NextrayMeasuresAnatomicalApex {
		return this.isAnatomicalAxisInserted ? this._canvasSrv.anatApexDistAP : {};
	}

	/**
	* Get current measures of proximal apex in LT View.
	*/
	get anatApexProxLT(): NextrayMeasuresAnatomicalApex {
		return this.isAnatomicalAxisInserted ? this._canvasSrv.anatApexProxLT : {};
	}

	/**
	* Get current measures of distal apex in LT View.
	*/
	get anatApexDistLT(): NextrayMeasuresAnatomicalApex {
		return this.isAnatomicalAxisInserted ? this._canvasSrv.anatApexDistLT : {};
	}

	/**
	* Get current axial rotation value of anatomical axis in deformity analysis.
	*/
	get defAnatAxialRot(): number {
		return this._canvasSrv.defAnatAxialRot;
	}

	/**
	* Get current axial rotation sign of anatomical axis in deformity analysis.
	*/
	get defAnatAxialRotSign(): AxialRotationEnum {
		return this._canvasSrv.defAnatAxialRotSign;
	}

	/**
	* Get current axial translation value of anatomical axis in deformity analysis.
	*/
	get defAnatAxialTrans(): number {
		return this._canvasSrv.defAnatAxialTrans;
	}

	/**
	* Get current axial translation sign of anatomical axis in deformity analysis.
	*/
	get defAnatAxialTransSign(): AxialTranslationEnum {
		return this._canvasSrv.defAnatAxialTransSign;
	}

	/**
	 * Get anatomical axial translation reference.
	 */
	get anatAxialTranslRef(): ViewType {
		return this._canvasSrv.anatAxialTranslRef;
	}

	/**
	* Get current deformity measures of contralateral axis.
	*/
	get defCLMeasures(): NextRayMeasuresDeformityAP {
		return this._canvasSrv.defCLMeasures;
	}

	/**
	* Get current correction measures
	*/
	get eocMeasures(): NextrayMeasuresEoCMech {
		return this._canvasSrv ? this._canvasSrv.eocMeasures : null;
	}
	/**
	* Get current correction measures of anatomical analysis.
	*/
	get eocMeasuresAnat(): NextrayMeasuresEoCAnat {
		return this._canvasSrv?.eocMeasuresAnat;
	}
	/**
	* Get current axial rotation value of anatomical axis in correction analysis.
	*/
	get eocAnatAxialRot(): number {
		return this._canvasSrv.eocAnatAxialRot;
	}
	/**
	* Get current axial rotation sign of anatomical axis in correction analysis.
	*/
	get eocAnatAxialRotSign(): AxialRotationEnum {
		return this._canvasSrv.eocAnatAxialRotSign;
	}
	/**
	* Get current suggested bone length in correction analysis.
	*/
	get eocSuggestedBoneLength(): number {
		return this.isAnatomicalAxisInserted ? this._canvasSrv.eocSuggestedBoneLength : 0;
	}

	/**
	* Get current scale factor on AP image
	*/
	get scaleFactorAP(): number {
		return this._canvasSrv.scaleFactorAP;
	}

	/**
	* Get current scale factor on Lateral image
	*/
	get scaleFactorLT(): number {
		return this._canvasSrv.scaleFactorLT;
	}

	/**
	* Get current layers state
	*/
	get layers(): LayerElements {
		return this._canvasSrv.layers;
	}

	/**
	* Get current layers values.
	*/
	get layersValue(): LayerElements {
		return this._canvasSrv.layersValue;
	}

	/**
	* Check if layers component is visible
	*/
	get layersVisible(): boolean {
		return this._canvasSrv.layersVisible;
	}

	/**
	* Check if mechanical axis is inserted in AP.
	*/
	get isAPMechanicalAxisInserted(): boolean {
		return this._canvasSrv.isAPMechanicalAxisInserted;
	}

	/**
	* Check if mechanical axis is inserted in Lateral.
	*/
	get isLTMechanicalAxisInserted(): boolean {
		return this._canvasSrv.isLTMechanicalAxisInserted;
	}

	/**
	* Check if contralateral axis is inserted.
	*/
	get isContralateralAxisInserted(): boolean {
		return this._canvasSrv.isContralateralAxisInserted;
	}

	/**
	* Check if anatomical axis is inserted.
	*/
	get isAnatomicalAxisInserted(): boolean {
		return this._canvasSrv.isAnatomicalAxisInserted;
	}

	/**
	* Indicates deformity analysis type.
	*/
	get deformityAnalysisType(): DeformityAnalysisType {
		return this._canvasSrv.deformityAnalysisType;
	}
	/**
	* Check if reference point is inserted.
	*/
	get isReferencePointInserted(): boolean {
		return this._canvasSrv.isReferencePointInserted;
	}

	/**
	* Check if third line is inserted.
	*/
	get isThirdLineInserted(): boolean {
		return this._canvasSrv.isThirdLineInserted;
	}
	/**
	* Indicates if anatomical axis will sync translation and rotations.
	*/
	get syncAnatomicalAxis(): boolean {
		return this._canvasSrv.syncAnatomicalAxis;
	}
	/**
	 * Indicates if apex is too far from axis in AP view. 
	 */
	get apexTooFarAP(): boolean {
		return this._canvasSrv.apexTooFarAP;
	}
	/**
	 * Indicates if apex is too far from axis in LT view. 
	 */
	get apexTooFarLT(): boolean {
		return this._canvasSrv.apexTooFarLT;
	}
	/**
	 * Indicates if osteotomy is too far in AP view. 
	 */
	get ostTooFarAP(): boolean {
		return this._canvasSrv.ostTooFarAP;
	}
	/**
	 * Indicates if osteotomy is too far in LT view. 
	 */
	get ostTooFarLT(): boolean {
		return this._canvasSrv.ostTooFarLT;
	}

	/**
	* Check if anatomical deformity parameters are invalid.
	*/
	get isInvalidAnatDefMeasure(): boolean {
		return (this.anatApexProxAP?.def_angDef > 90 || this.anatApexDistAP?.def_angDef > 90 || this.anatApexProxAP?.def_transl > 100) ||
			(this.anatApexProxLT?.def_angDef > 90 || this.anatApexDistLT?.def_angDef > 90 || this.anatApexProxLT?.def_transl > 100) ||
			(this.isFracture && (this.anatApexProxAP?.def_ax_transl > 200 || this.anatApexProxLT?.def_ax_transl > 200 || this.anatAxialTranslRef == null)) ||
			(this.isPostOp && this.eocSuggestedBoneLength > 200);
	}

	/**
	* Check if anatomical correction parameters are invalid.
	*/
	get isInvalidAnatCorrMeasure(): boolean {
		return (this.eocMeasuresAnat?.eoc_ap_overAng > 90 || this.eocMeasuresAnat?.eoc_ap_overTransl > 100) ||
			(this.eocMeasuresAnat?.eoc_lt_overAng > 90 || this.eocMeasuresAnat?.eoc_lt_overTransl > 100) ||
			(this.eocSuggestedBoneLength > 200 || this.eocMeasuresAnat?.eoc_boneLength > 200);
	}

	/**
	* Check if deformity analysis with mechanical axis is complete.
	*/
	get isMechDeformityDone(): boolean {
		return (!this.hasAP || this.isAPMechanicalAxisInserted) && (!this.hasLT || this.isLTMechanicalAxisInserted);
	}

	/**
	* Check if deformity analysis with anatomical axis is complete.
	*/
	get isAnatDeformityDone(): boolean {
		return this.isAnatomicalAxisInserted && (!this.isFracture || this.anatAxialTranslRef != null) && !this.isInvalidAnatDefMeasure;
	}

	/**
	* Check if deformity analysis is complete.
	*/
	get isDeformityDone(): boolean {
		return this.isMechDeformityDone || this.isAnatDeformityDone;
	}

	/**
	* Check if correction analysis is complete.
	*/
	get isCorrectionDone(): boolean {
		switch (this.deformityAnalysisType) {
			case DeformityAnalysisType.mechanical: return this.isEocCut;
			case DeformityAnalysisType.anatomical: return this.isEocCut && !this.isInvalidAnatCorrMeasure;
			default: return false;
		}
	}

	/**
	* Check if eoc cut is active.
	*/
	get isEocCut(): boolean {
		return this._canvasSrv.isEocCut;
	}

	/**
	* Get eoc cut type.
	*/
	get osteotomyCut(): OsteotomyCut {
		return this._canvasSrv.osteotomyCut;
	}

	/**
	* Get selected apex.
	*/
	get selectedApex(): SelectedApexMech | SelectedApexAnat {
		return this._canvasSrv.selectedApex;
	}

	/**
	* Check if plate is inserted.
	*/
	get isPlateInserted(): boolean {
		return this._canvasSrv.isPlateInserted;
	}

	/**
	* Get plate orientation type.
	*/
	get plateOrientation(): PlateOrientationType {
		return this._canvasSrv.plateOrientation;
	}

	/**
	* Get plate.
	*/
	get plate(): Plate {
		return this._canvasSrv.plate;
	}

	/**
	* Get JPS Extended status.
	*/
	get hasJPSExtended(): boolean {
		return this._canvasSrv.hasJPSExtended;
	}

	/**
	* Check if plate is loading in AP view.
	*/
	get isAPPlateLoading(): boolean {
		return this._canvasSrv.isAPPlateLoading;
	}

	/**
	* Check if plate is loading in Lateral view.
	*/
	get isLTPlateLoading(): boolean {
		return this._canvasSrv.isLTPlateLoading;
	}

	/**
	* Get axial roatation value of femur.
	*/
	get def_femur_axialRotation(): number {
		return this._canvasSrv.def_femur_axialRotation;
	}

	/**
	* Get axial roatation sign of femur.
	*/
	get def_femur_axialRotationSign(): AxialRotationEnum {
		return this._canvasSrv.def_femur_axialRotationSign;
	}

	/**
	* Get axial roatation value of tibia.
	*/
	get def_tibia_axialRotation(): number {
		return this._canvasSrv.def_tibia_axialRotation;
	}

	/**
	* Get axial roatation sign of tibia.
	*/
	get def_tibia_axialRotationSign(): AxialRotationEnum {
		return this._canvasSrv.def_tibia_axialRotationSign;
	}

	/**
	* Get standard angle of LPFA.
	*/
	get def_standardAngles_LPFA(): number {
		return this._canvasSrv.def_standardAngles_LPFA;
	}

	/**
	* Get standard angle of mLDFA.
	*/
	get def_standardAngles_mLDFA(): number {
		return this._canvasSrv.def_standardAngles_mLDFA;
	}

	/**
	* Get standard angle of MPTA.
	*/
	get def_standardAngles_MPTA(): number {
		return this._canvasSrv.def_standardAngles_MPTA;
	}

	/**
	* Get standard angle of LDTA.
	*/
	get def_standardAngles_LDTA(): number {
		return this._canvasSrv.def_standardAngles_LDTA;
	}

	/**
	* Get standard angle of PPTA.
	*/
	get def_standardAngles_PPTA(): number {
		return this._canvasSrv.def_standardAngles_PPTA;
	}

	/**
	* Get standard angle of ADTA.
	*/
	get def_standardAngles_ADTA(): number {
		return this._canvasSrv.def_standardAngles_ADTA;
	}

	/**
	 * Get brightness of AP image.
	 */
	public get brightnessAP(): number {
		return this._canvasSrv.brightnessAP;
	}

	/**
	 * Get brightness of Lateral image.
	 */
	public get brightnessLT(): number {
		return this._canvasSrv.brightnessLT;
	}

	/**
	 * Get contrast of AP image.
	 */
	public get contrastAP(): number {
		return this._canvasSrv.contrastAP;
	}

	/**
	 * Get contrast of Lateral image.
	 */
	public get contrastLT(): number {
		return this._canvasSrv.contrastLT;
	}

	/**
	 * Gets bone type of Lateral view.
	 */
	public get boneTypeLT(): BoneTypeEnum {
		return this._canvasSrv.boneTypeLT;
	}

	/**
	 * Indicates if measurement tool is selected in AP view.
	 */
	public get isMeasurementToolSelectedAP(): boolean {
		return this._canvasSrv.isMeasurementToolSelectedAP;
	}

	/**
	 * Indicates if measurement tool is selected in LT view.
	 */
	public get isMeasurementToolSelectedLT(): boolean {
		return this._canvasSrv.isMeasurementToolSelectedLT;
	}

}
