<!-- Box table -->
<div class="box-table">

	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.LLD_RECAP_TITLE }}
		<!--<span class="material-icons icon-expand">expand_more</span>-->
		<div class="fake-material-icon icon-expand expand"></div>
	</span>

	<table aria-label="lld measures" class="table" [ngbCollapse]="isCollapsed">
		<thead>
			<tr>
				<th></th>
				<th>{{ labels.LLD_RECAP_LENGTH_HEADER }} <br /> ({{ isLeft ? sideEnum.Left : sideEnum.Right }})</th>
				<th>{{ labels.LLD_RECAP_CL_LENGTH_HEADER }} <br /> ({{ isLeft ? sideEnum.Right : sideEnum.Left }})</th>
				<th>{{ labels.LLD_RECAP_LLD_HEADER }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_RECAP_LONGLEG }}</td>
				<td>{{ eocMeasures?.eoc_ap_fullLength }}</td>
				<td>{{ defCLMeasures?.def_ap_fullLength_ctrlateral }}</td>
				<td>{{ longlegLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg || isFemur">
				<td>{{ labels.LLD_RECAP_FEMUR }}</td>
				<td>{{ eocMeasures?.eoc_ap_femurLength }}</td>
				<td>{{ defCLMeasures?.def_ap_femurLength_ctrlateral }}</td>
				<td>{{ femurLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg || isTibia">
				<td>{{ labels.LLD_RECAP_TIBIA }}</td>
				<td>{{ eocMeasures?.eoc_ap_tibiaLength }}</td>
				<td>{{ defCLMeasures?.def_ap_tibiaLength_ctrlateral }}</td>
				<td>{{ tibiaLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_RECAP_FEMUR_TIBIA }}</td>
				<td>{{ trueLength }}</td>
				<td>{{ trueLengthCL }}</td>
				<td>{{ trueLengthLLD }}</td>
			</tr>
		</tbody>
	</table>

</div>