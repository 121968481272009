import { Component } from '@angular/core';
import { SelectedApexAnat } from '@ortho-next/nextray-core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';



/**
 * Table for anatomical axis measures in anatomical analysis.
 */
@Component({
	selector: 'aa-ap-table',
	templateUrl: './aa-ap-table.component.html'
})
export class AaApTableComponent extends BaseComponent {

	isCollapsed: boolean = false;

	private readonly MAX_VALUE = 90;
	private readonly MAX_TRANSL = 100;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	// VISIBILITY

	/**
	* Check if proximal table is visible in AP image.
	*/
	get isProxTabVisible(): boolean {
		return this.selectedApex !== SelectedApexAnat.distal;
	}

	/**
	* Check if distal table is visible in AP image.
	*/
	get isDistTabVisible(): boolean {
		return (this.isThirdLineInserted && !this.selectedApex) || this.selectedApex === SelectedApexAnat.distal;
	}

	// MEASURES

	/**
	* Get angular deformity of proximal apex in AP image.
	*/
	get apProxAngDef(): string {
		return this.anatApexProxAP.def_angDef || this.anatApexProxAP.def_angDef === 0 ? `${this.anatApexProxAP.def_angDef} ${this.anatApexProxAP.def_angDefSign}` : '';
	}

	/**
	* Get lateral translation of proximal apex in AP image.
	*/
	get apProxLatTransl(): string {
		return this.anatApexProxAP.def_transl || this.anatApexProxAP.def_transl === 0 ? `${this.anatApexProxAP.def_transl} ${this.anatApexProxAP.def_translSign}` : '';
	}

	/**
	* Get angular deformity of distal apex in AP image.
	*/
	get apDistAngDef(): string {
		return this.anatApexDistAP.def_angDef || this.anatApexDistAP.def_angDef === 0 ? `${this.anatApexDistAP.def_angDef} ${this.anatApexDistAP.def_angDefSign}` : '';
	}

	/**
	* Get lateral translation of distal apex in AP image.
	*/
	get apDistLatTransl(): string {
		return this.anatApexDistAP.def_transl || this.anatApexDistAP.def_transl === 0 ? `${this.anatApexDistAP.def_transl} ${this.anatApexDistAP.def_translSign}` : '';
	}

	// VALIDATION

	/**
	* Check if angular deformity of proximal apex in AP image is valid.
	*/
	get isValidAPProxAngDef(): boolean {
		return !this.anatApexProxAP?.def_angDef || this.anatApexProxAP.def_angDef <= this.MAX_VALUE;
	}

	/**
	* Check if lateral translation of proximal apex in AP image is valid.
	*/
	get isValidAPProxLatTransl(): boolean {
		return !this.anatApexProxAP?.def_transl || this.anatApexProxAP.def_transl <= this.MAX_TRANSL;
	}

	/**
	* Check if angular deformity of distal apex in AP image is valid.
	*/
	get isValidAPDistAngDef(): boolean {
		return !this.anatApexDistAP?.def_angDef || this.anatApexDistAP.def_angDef <= this.MAX_VALUE;
	}

	/**
	* Check if lateral translation of distal apex in AP image is valid.
	*/
	get isValidAPDistLatTransl(): boolean {
		return !this.anatApexDistAP?.def_transl || this.anatApexDistAP.def_transl <= this.MAX_TRANSL;
	}

}
