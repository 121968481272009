import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NextrayModel } from '@ortho-next/nextray-core/Core/Save';
import { InitialFlipType } from '@ortho-next/nextray-core/Models/CaseModel';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Bridge } from '../../nextray/Core/Bridge';
import { Main } from '../../nextray/Core/Main';
import { AppModel } from '../../nextray/Models/AppModel';
import { CaseModel } from '../../nextray/Models/CaseModel';
import { DeformityParameters, Image, ImageOrientationEnum, isGuid, LanguageService, Plan } from '../core';
import { CaseService } from './case.service';
import { ImageService } from './image.service';
import { ModelService } from './model.service';
import { TlhexService } from './tlhex.service';


export interface Canvas3DReadyArgs {
	bridge: Bridge;
	model: AppModel;
}


/**
* This service initialized the canvas component.
*/
@Injectable()
export class MainCanvasLoaderService {

	private _initialized = false;
	private _canvas3DArgs: Canvas3DReadyArgs;

	public canvas3D: Subject<Canvas3DReadyArgs> = new Subject<Canvas3DReadyArgs>();

	constructor(
		private modelSrv: ModelService,
		private caseSrv: CaseService,
		private imgSrv: ImageService,
		private route: ActivatedRoute,
		private titleSrv: Title,
		private langSrv: LanguageService,
		private tlhexSrv: TlhexService
	) { }

	/**
	* Prepare canvas component.
	*/
	public init(): Observable<void> {
		if (!this._initialized) {
			this._initialized = true;

			try {
				const main = new Main(document.getElementById('canvas-container') as HTMLDivElement);
				this._canvas3DArgs = {
					bridge: main.bridge,
					model: main.model
				}
				this.canvas3D.next(this._canvas3DArgs);
				this.canvas3D.complete();
			} catch (error) {
				if (error instanceof Error && error.message === 'Error creating WebGL context.') {
					return throwError(() => new Error('WebglDisabled'));
				}
			}

			if (this.isScreenResolutionInvalid) {
				return throwError(() => new Error('ScreenResolutionInvalid'));
			}

			const caseGuid = this.route.snapshot.queryParamMap.get('caseGuid');
			return this.loadPlan(caseGuid).pipe(
				switchMap(plan => this.loadAvailableProducts(plan)),
				switchMap(plan => this.loadModel(plan))
			);
		}
	}

	private get isScreenResolutionInvalid(): boolean {
		return (window.screen.width * window.devicePixelRatio < 1280) || (window.screen.height * window.devicePixelRatio < 768);
	}

	private loadPlan(planGuid: string): Observable<Plan> {
		if (!isGuid(planGuid)) return throwError(() => new Error('PlanNotValid'));
		return this.caseSrv.getCase(planGuid).pipe(
			catchError((err: Error) => {
				return err.message === 'ItemNotFound' ? throwError(() => new Error('PlanNotFound')) : throwError(() => err)
			}),
			switchMap(plan => {
				return plan.isReceived ? throwError(() => new Error('PlanNotValid')) : of(plan);
			}),
			tap(plan => this.setTitle(plan))
		);
	}

	private setTitle(plan: Plan): void {
		const titleLbl: string = this.langSrv.getLabel('APP_TITLE_PATIENT_PLAN');
		this.titleSrv.setTitle(titleLbl.replace('{PAT}', plan.patient.number).replace('{PLAN}', plan.number));
	}

	private loadAvailableProducts(plan: Plan): Observable<Plan> {
		return this.caseSrv.getAvailableProducts(plan).pipe(
			switchMap(prods => {
				return !prods?.length ? throwError(() => new Error('PlanNotValid')) : of(plan);
			})
		);
	}

	private loadModel(plan: Plan): Observable<void> {
		return this.modelSrv.restoreModel(plan.id, plan.userGuid).pipe(
			switchMap(rayModel => this.setMainModel(plan, rayModel?.model))
		);
	}

	private observAP = (plan: Plan) => plan.apImageGuid ? this.imgSrv.getImage(plan.apImageGuid) : of(null);
	private observLT = (plan: Plan) => plan.ltImageGuid ? this.imgSrv.getImage(plan.ltImageGuid) : of(null);

	private observCheckUpDef = (plan: Plan) => plan.isCheckUp ? this.tlhexSrv.getDeformityParameters(plan.id, plan.userGuid) : of(null);
	private observCheckUpEOC = (plan: Plan) => plan.isCheckUp ? this.tlhexSrv.getEOCDeformityParameters(plan.id, plan.userGuid) : of(null);

	private setMainModel(plan: Plan, rayModel: NextrayModel): Observable<void> {
		return combineLatest([
			this.observAP(plan),
			this.observLT(plan),
			this.observCheckUpDef(plan),
			this.observCheckUpEOC(plan)
		]).pipe(
			map(([imgAP, imgLT, checkUpDef, checkUpEOC]) => this.caseModelMapper(plan, imgAP, imgLT, rayModel, checkUpDef, checkUpEOC)),
			map(caseModel => this._canvas3DArgs.bridge.mapEvent('setModel', caseModel))
		);
	}

	private caseModelMapper(plan: Plan, imgAP: Image, imgLT: Image, rayModel: NextrayModel, checkUpDef: DeformityParameters, checkUpEOC: DeformityParameters): CaseModel {
		if (!plan) return null;
		return {
			// plan data
			boneType: plan.boneType,
			version: plan.version,
			type: plan.type,
			referenceType: plan.referenceType,
			side: plan.side,
			isPostOperative: plan.isPostOperative,
			// image AP data
			imgAP: imgAP?.url,
			scaleFactorAP: imgAP?.scaleFactor,
			flipTypeAP: this.flipTypeMapper(imgAP?.orientation),
			// image LT data
			imgLT: imgLT?.url,
			scaleFactorLT: imgLT?.scaleFactor,
			flipTypeLT: this.flipTypeMapper(imgLT?.orientation),
			// ray model
			model: rayModel,
			// checkup
			checkUpDeformity: checkUpDef,
			checkUpEOC: checkUpEOC
		}
	}

	private flipTypeMapper(orientationType: ImageOrientationEnum): InitialFlipType {
		switch (orientationType) {
			case ImageOrientationEnum.Nothing: return null;
			case ImageOrientationEnum.Horizontal: return InitialFlipType.horizontal;
			case ImageOrientationEnum.Vertical: return InitialFlipType.vertical;
			case ImageOrientationEnum.Rotate180: return InitialFlipType.rotate180;
			default: return null;
		}
	}

}
