import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ViewStateType } from '@ortho-next/nextray-core/Models/AppModel';
import { SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StateTypes } from '../../nextray/States/State';
import { AnatomicalSideEnum, BoneTypeEnum } from '../core';
import { CanvasService } from './canvas.service';


/**
* This service handles help modal
*/
@Injectable()
export class HelpService {

	private fileNameList: any;
	private _isLoaded: boolean;

	constructor(
		private http: HttpClient,
		private canvasSrv: CanvasService,
		private sanitizer: DomSanitizer
	) { }

	/**
	* Check if help file name list is loaded
	*/
	get isLoaded(): boolean {
		return this._isLoaded;
	}

	/**
	* Load help file name list
	*/
	loadFileNames(): Observable<any> {
		return this.http.get(environment.helpSite.fileNames).pipe(tap(list => {
			this.fileNameList = list;
			this._isLoaded = true;
		}));
	}

	/**
	* Get safe url of help
	*/
	get safeUrl(): SafeUrl {
		const url = this.helpUrl;
		return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : null;
	}

	private get helpUrl(): string {
		const fileName = this.fileNameList ? this.fileNameList[this.fileKey] : null;
		return fileName ? `${environment.helpSite.baseUrl}/${fileName}.html` : null;
	}

	private get fileKey(): string {
		switch (this.canvasSrv.stateType) {
			case StateTypes.new: return `NEW`;
			case StateTypes.calibrated: return `CALIBRATED`;
			case StateTypes.deformityAnalysis: return `DEFORMITY_${this.boneType}_${this.side}_${this.activeView}`;
			case StateTypes.EOC: return `CORRECTION_${this.boneType}_${this.side}_${this.activeView}`;
			case StateTypes.templating: return `JPS_${this.osteotomyBone}_${this.side}_${this.activeView}_${this.osteotomySite}`;
			default: return ``;
		}
	}

	private get boneType(): string {
		if (!this.canvasSrv.currentCase) return '';
		switch (this.canvasSrv.currentCase.boneType) {
			case BoneTypeEnum.LongLeg: return 'FULLLEG';
			case BoneTypeEnum.Femur: return 'FEMUR';
			case BoneTypeEnum.Tibia: return 'TIBIA';
			case BoneTypeEnum.Ankle: return 'ANKLE';
			case BoneTypeEnum.Forefoot: return 'FOREFOOT';
			case BoneTypeEnum.Hindfoot: return 'HINDFOOT';
			default: return '';
		}
	}

	private get side(): string {
		if (!this.canvasSrv.currentCase) return '';
		switch (this.canvasSrv.currentCase.side) {
			case AnatomicalSideEnum.Left: return 'LEFT';
			case AnatomicalSideEnum.Right: return 'RIGHT';
			default: return '';
		}
	}

	private get activeView(): string {
		switch (this.canvasSrv.activeViewState) {
			case ViewStateType.AP: return 'AP';
			case ViewStateType.LT: return 'LAT';
			case ViewStateType.multiple: return 'MUL';
			default: return '';
		}
	}

	private get osteotomyBone(): string {
		switch (this.canvasSrv.selectedApex) {
			case SelectedApexMech.femurProximal:
			case SelectedApexMech.femurDistal: return 'FEMUR';
			case SelectedApexMech.tibiaProximal:
			case SelectedApexMech.tibiaDistal: return 'TIBIA';
			default: return '';
		}
	}

	private get osteotomySite(): string {
		switch (this.canvasSrv.selectedApex) {
			case SelectedApexMech.femurProximal:
			case SelectedApexMech.tibiaProximal: return 'PROXIMAL';
			case SelectedApexMech.femurDistal:
			case SelectedApexMech.tibiaDistal: return 'DISTAL';
			default: return '';
		}
	}

}
