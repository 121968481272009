<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.MA_AP_TABLE_COMPONENT_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<form [formGroup]="apMeasuresForm">
		<table aria-label="AP mechanical measures" class="table" [ngbCollapse]="isCollapsed">
			<thead>
				<tr>
					<th></th>
					<th>{{ labels.MA_AP_TABLE_COMPONENT_VALUE_HEADER }} <br /> ({{ isLeft ? sideEnum.Left : sideEnum.Right }})
					</th>
					<th>{{ labels.MA_AP_TABLE_COMPONENT_TARGET_HEADER }}</th>
					<th>{{ labels.MA_AP_TABLE_COMPONENT_CONTROLATERAL_HEADER }} <br />
						({{ isLeft ? sideEnum.Right : sideEnum.Left }})</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="isMAD" [ngClass]="{'row-selected': !isValidMAD }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_MAD }} <br /> {{ rangeMAD }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_MAD">{{ defAPMeasures.def_ap_MAD }}</td>
					<td class="align-middle"></td>
					<td [highlightCell]="defCLMeasures.def_ap_MAD_ctrlateral">{{ defCLMeasures.def_ap_MAD_ctrlateral }}</td>
				</tr>
				<tr *ngIf="isMNSA" [ngClass]="{'row-selected': !isValidMNSA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_MNSA }} <br /> {{ rangeMNSA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_MNSA">{{ defAPMeasures.def_ap_MNSA }}</td>
					<td class="align-middle"></td>
					<td [highlightCell]="defCLMeasures.def_ap_MNSA_ctrlateral">{{ defCLMeasures.def_ap_MNSA_ctrlateral }}</td>
				</tr>
				<tr *ngIf="isLPFA" [ngClass]="{'row-selected': !isValidLPFA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_LPFA }} <br /> {{ rangeLPFA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_LPFA">{{ defAPMeasures.def_ap_LPFA }}</td>
					<td class="align-middle">
						<input type="text" maxlength="2" name="LPFA" formControlName="LPFA" (keyup.enter)="updateStandardLPFA()"
							(blur)="updateStandardLPFA()" [readonly]="isDisableStandardLPFA"
							[ngClass]="{'is-invalid': apMeasuresForm.controls.LPFA.invalid }" />
					</td>
					<td [highlightCell]="defCLMeasures.def_ap_LPFA_ctrlateral">
						<span class="material-icons copy-value" [ngClass]="{'disabled': isDisableCopyLPFA }"
							(click)="copyStandardLPFA()">file_copy</span>
						{{ defCLMeasures.def_ap_LPFA_ctrlateral }}
					</td>
				</tr>
				<tr *ngIf="isMPFA" [ngClass]="{'row-selected': !isValidMPFA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_MPFA }} <br /> {{ rangeMPFA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_MPFA">{{ defAPMeasures.def_ap_MPFA }}</td>
					<td class="align-middle"></td>
					<td [highlightCell]="defCLMeasures.def_ap_MPFA_ctrlateral">{{ defCLMeasures.def_ap_MPFA_ctrlateral }}</td>
				</tr>
				<tr *ngIf="isMLDFA" [ngClass]="{'row-selected': !isValidMLDFA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_MLDFA }} <br /> {{ rangeMLDFA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_mLDFA">{{ defAPMeasures.def_ap_mLDFA }}</td>
					<td class="align-middle">
						<input type="text" maxlength="2" name="MLDFA" formControlName="MLDFA" (keyup.enter)="updateStandardMLDFA()"
							(blur)="updateStandardMLDFA()" [readonly]="isDisableStandardMLDFA"
							[ngClass]="{'is-invalid': apMeasuresForm.controls.MLDFA.invalid }" />
					</td>
					<td [highlightCell]="defCLMeasures.def_ap_mLDFA_ctrlateral">
						<span class="material-icons copy-value" [ngClass]="{'disabled': isDisableCopyMLDFA }"
							(click)="copyStandardMLDFA()">file_copy</span>
						{{ defCLMeasures.def_ap_mLDFA_ctrlateral }}
					</td>
				</tr>
				<tr *ngIf="isJLCA" [ngClass]="{'row-selected': !isValidJLCA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_KNEE_JLCA }} <br /> {{ rangeJLCA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_JLCA">{{ defAPMeasures.def_ap_JLCA }}</td>
					<td class="align-middle"></td>
					<td [highlightCell]="defCLMeasures.def_ap_JLCA_ctrlateral">{{ defCLMeasures.def_ap_JLCA_ctrlateral }}</td>
				</tr>
				<tr *ngIf="isKneeTransl" [ngClass]="{'row-selected': !isValidKneeTransl }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_KNEE_TRANSL }} <br /> {{ rangeKneeTransl }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_kneeTranslation">
						{{ defAPMeasures.def_ap_kneeTranslation }}</td>
					<td class="align-middle"></td>
					<td [highlightCell]="defCLMeasures.def_ap_kneeTranslation_ctrlateral">
						{{ defCLMeasures.def_ap_kneeTranslation_ctrlateral }}</td>
				</tr>
				<tr *ngIf="isMPTA" [ngClass]="{'row-selected': !isValidMPTA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_MPTA }} <br /> {{ rangeMPTA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_MPTA">{{ defAPMeasures.def_ap_MPTA }}</td>
					<td class="align-middle">
						<input type="text" maxlength="2" name="MPTA" formControlName="MPTA" (keyup.enter)="updateStandardMPTA()"
							(blur)="updateStandardMPTA()" [readonly]="isDisableStandardMPTA"
							[ngClass]="{'is-invalid': apMeasuresForm.controls.MPTA.invalid }" />
					</td>
					<td [highlightCell]="defCLMeasures.def_ap_MPTA_ctrlateral">
						<span class="material-icons copy-value" [ngClass]="{'disabled': isDisableCopyMPTA }"
							(click)="copyStandardMPTA()">file_copy</span>
						{{ defCLMeasures.def_ap_MPTA_ctrlateral }}
					</td>
				</tr>
				<tr *ngIf="isLDTA" [ngClass]="{'row-selected': !isValidLDTA }">
					<td class="align-middle">{{ labels.MA_AP_TABLE_COMPONENT_LDTA }} <br /> {{ rangeLDTA }}</td>
					<td class="align-middle" [highlightCell]="defAPMeasures.def_ap_LDTA">{{ defAPMeasures.def_ap_LDTA }}</td>
					<td class="align-middle">
						<input type="text" maxlength="2" name="LDTA" formControlName="LDTA" (keyup.enter)="updateStandardLDTA()"
							(blur)="updateStandardLDTA()" [readonly]="isDisableStandardLDTA"
							[ngClass]="{'is-invalid': apMeasuresForm.controls.LDTA.invalid }" />
					</td>
					<td [highlightCell]="defCLMeasures.def_ap_LDTA_ctrlateral">
						<span class="material-icons copy-value" [ngClass]="{'disabled': isDisableCopyLDTA }"
							(click)="copyStandardLDTA()">file_copy</span>
						{{ defCLMeasures.def_ap_LDTA_ctrlateral }}
					</td>
				</tr>
			</tbody>
		</table>
	</form>
</div>