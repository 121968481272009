<!-- MENU CORRECTION MECHANICAL -->
<div class="box-menu-settings" [ngClass]="{'active': isDeformityDone }">

  <div class="row-box">
    <h4>{{ labels.TOOLS_MENU_COMPONENT_CORRECTION }}</h4>
    <div class="check-success ml-auto" *ngIf="isCorrectionDone"></div>
  </div>

  <div class="divider-box"></div>

  <div class="row-box" *ngIf="stateType !== stateTypeEnum.EOC; else CorrectionBlock"
    [ngClass]="{'clickable': isDeformityDone, 'not-allowed': !isDeformityDone }" (click)="enableCorrection()">
    <i class="material-icons">more_horiz</i>
    <span>{{ labels.TOOLS_MENU_COMPONENT_ACTIVATE }}</span>
  </div>

  <ng-template #CorrectionBlock>

    <ng-container *ngIf="deformityAnalysisType === defTypeEnum.mechanical">

      <div class="row-box" [ngClass]="{'clickable': !isEocCut, 'disabled': !!isEocCut }"
        (click)="openOsteotomyWorkflow()">
        <div class="fake-material-icon osteotomy"></div>
        <span>{{ labels.TOOLS_MENU_COMPONENT_OSTEOTOMY_SITE }}</span>
      </div>
      <form [formGroup]="cutForm" *ngIf="cutForm">
        <div class="row-box">
          <div class="form-group pb-0">
            <div class="custom-control custom-radio custom-control-inline pt-0" (click)="setOpening()">
              <input type="radio" id="opening" name="cutType" class="custom-control-input" [value]="cutType.opening"
                formControlName="cutType" />
              <label class="custom-control-label" for="opening">{{ labels.TOOLS_MENU_COMPONENT_OPENING }}</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline pt-0" (click)="setClosing()">
              <input type="radio" id="closing" name="cutType" class="custom-control-input" [value]="cutType.closing"
                formControlName="cutType" />
              <label class="custom-control-label" for="closing">{{ labels.TOOLS_MENU_COMPONENT_CLOSING }}</label>
            </div>
          </div>
        </div>
        <div class="row-box">
          <i class="material-icons">crop_rotate</i>
          <span>{{ labels.TOOLS_MENU_COMPONENT_CUT }}</span>
          <div class="form-group switch-custom-container pb-0 ml-4 mr-3 mb-0" [ngClass]="{'disabled': !osteotomyCut }">
            <label class="switch" (click)="setCut()">
              <input type="checkbox" formControlName="cut" />
              <span></span>
            </label>
            <p class="mb-0">{{ labels.TOOLS_MENU_COMPONENT_ON_OFF }}</p>
          </div>
          <i class="material-icons img-action p-2" style="max-width: 3rem;"
            [ngClass]="{'clickable': isEocCut, 'disabled': !isEocCut }" (click)="resetCut()">
            sync
          </i>
        </div>
      </form>

    </ng-container>

    <ng-container *ngIf="deformityAnalysisType === defTypeEnum.anatomical">

      <div class="row-box clickable" *ngIf="isThirdLineInserted" (click)="openAnatOsteotomyWorkflow()">
        <div class="fake-material-icon osteotomy"></div>
        <span>{{ labels.TOOLS_MENU_COMPONENT_OSTEOTOMY_SITE }}</span>
      </div>
      <form [formGroup]="cutForm" *ngIf="cutForm">
        <div class="row-box">
          <i class="material-icons">crop_rotate</i>
          <span>{{ labels.TOOLS_MENU_COMPONENT_CUT_ANATOMICAL }}</span>
          <div class="form-group switch-custom-container pb-0 ml-4 mr-3 mb-0"
            [ngStyle]="{'pointer-events': isAnatCutDisable ? 'none' : 'auto'}"
            [ngClass]="{'disabled': isAnatCutDisable }">
            <label class="switch" (click)="setAnatCut()">
              <input type="checkbox" formControlName="cut" />
              <span></span>
            </label>
          </div>
          <i class="material-icons img-action p-2" style="max-width: 3rem;"
            [ngClass]="{'clickable': isEocCut, 'disabled': !isEocCut }" (click)="resetCut()">
            sync
          </i>
        </div>
      </form>

    </ng-container>


  </ng-template>

</div>
