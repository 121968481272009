<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.MA_LT_TABLE_COMPONENT_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<form [formGroup]="lateralMeasuresForm">
		<table aria-label="LT mechanical measures" class="table" [ngbCollapse]="isCollapsed">
			<thead>
				<tr>
					<th></th>
					<th>{{ labels.MA_LT_TABLE_COMPONENT_VALUE_HEADER }} <br /> ({{ isLeft ? sideEnum.Left : sideEnum.Right }})
					</th>
					<th>{{ labels.MA_LT_TABLE_COMPONENT_TARGET_HEADER }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="isPDFA" [ngClass]="{'row-selected': !isValidPDFA }">
					<td class="align-middle">{{ labels.MA_LT_TABLE_COMPONENT_PDFA }} <br /> {{ rangePDFA }}</td>
					<td class="align-middle" [highlightCell]="defLTMeasures.def_lt_PDFA">{{ defLTMeasures.def_lt_PDFA }}</td>
					<td class="align-middle">
					</td>
				</tr>
				<tr *ngIf="isKneeTransl" [ngClass]="{'row-selected': !isValidKneeTransl }">
					<td class="align-middle">{{ labels.MA_LT_TABLE_COMPONENT_KNEE_TRANSL }} <br /> {{ rangeKneeTransl }}</td>
					<td class="align-middle" [highlightCell]="defLTMeasures.def_lt_kneeTranslation">
						{{ defLTMeasures.def_lt_kneeTranslation }}</td>
					<td class="align-middle">
					</td>
				</tr>
				<tr *ngIf="isPPTA" [ngClass]="{'row-selected': !isValidPPTA }">
					<td class="align-middle">{{ labels.MA_LT_TABLE_COMPONENT_PPTA }} <br /> {{ rangePPTA }}</td>
					<td class="align-middle" [highlightCell]="defLTMeasures.def_lt_PPTA">{{ defLTMeasures.def_lt_PPTA }}</td>
					<td class="align-middle">
						<input type="text" maxlength="2" name="PPTA" formControlName="PPTA" (keyup.enter)="updateStandardPPTA()"
							(blur)="updateStandardPPTA()"
							[readonly]="!defLTMeasures.def_lt_PPTA || stateType !== stateTypeEnum.deformityAnalysis"
							[ngClass]="{'is-invalid': lateralMeasuresForm.controls.PPTA.invalid }" />
					</td>
				</tr>
				<tr *ngIf="isADTA" [ngClass]="{'row-selected': !isValidADTA}">
					<td class="align-middle">{{ labels.MA_LT_TABLE_COMPONENT_ADTA }} <br /> {{ rangeADTA }}</td>
					<td class="align-middle" [highlightCell]="defLTMeasures.def_lt_ADTA">{{ defLTMeasures.def_lt_ADTA }}</td>
					<td class="align-middle">
						<input type="text" maxlength="2" name="ADTA" formControlName="ADTA" (keyup.enter)="updateStandardADTA()"
							(blur)="updateStandardADTA()"
							[readonly]="!defLTMeasures.def_lt_ADTA || stateType !== stateTypeEnum.deformityAnalysis"
							[ngClass]="{'is-invalid': lateralMeasuresForm.controls.ADTA.invalid }" />
					</td>
				</tr>
			</tbody>
		</table>
	</form>
</div>