import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AxialRotationEnum } from '@ortho-next/nextray-core/Models/AppModel';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of apex measures.
*/
@Component({
	selector: 'apex-table',
	templateUrl: './apex-table.component.html',
	styles: []
})
export class ApexTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = true;

	axialRotForm: FormGroup;
	axialRotEnum = AxialRotationEnum;

	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.initAxialRotForm();
	}

	// APEX TABLE

	/**
	* Get apex measure of femur in AP image.
	*/
	get apFemurMeasure(): string {
		return this.defAPMeasures.def_ap_apexFemur || this.defAPMeasures.def_ap_apexFemur === 0 ? `${this.defAPMeasures.def_ap_apexFemur} ${this.defAPMeasures.def_ap_apexFemurSign}` : '';
	}

	/**
	* Get apex measure of tibia in AP image.
	*/
	get apTibiaMeasure(): string {
		return this.defAPMeasures.def_ap_apexTibia || this.defAPMeasures.def_ap_apexTibia === 0 ? `${this.defAPMeasures.def_ap_apexTibia} ${this.defAPMeasures.def_ap_apexTibiaSign}` : '';
	}

	/**
	* Get apex measure of tibia in Lateral image.
	*/
	get ltTibiaMeasure(): string {
		return this.defLTMeasures.def_lt_apexTibia || this.defLTMeasures.def_lt_apexTibia === 0 ? `${this.defLTMeasures.def_lt_apexTibia} ${this.defLTMeasures.def_lt_apexTibiaSign}` : '';
	}


	// AXIAL ROTATION

	private initAxialRotForm(): void {
		const validators: ValidatorFn[] = [Validators.pattern(this.twoDigitsPattern), Validators.min(0), Validators.max(99)];
		this.axialRotForm = new FormGroup({
			'axialRotFemur': new FormControl(this.def_femur_axialRotation, validators),
			'axialRotSignFemur': new FormControl(this.def_femur_axialRotationSign),
			'axialRotTibia': new FormControl(this.def_tibia_axialRotation, validators),
			'axialRotSignTibia': new FormControl(this.def_tibia_axialRotationSign)
		});
	}

	/**
	* Update axial rotation sign of femur.
	*/
	updateAxialRotSignFemur(sign: AxialRotationEnum): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			this.canvasSrv.dispatch('setFemurAxialRotationSign', sign);
		}
	}

	/**
	* Update axial rotation value of femur.
	*/
	updateAxialRotFemur(): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			if (this.axialRotForm.controls.axialRotFemur.valid) {
				const axialRotFemur = this.axialRotForm.value.axialRotFemur ? parseInt(this.axialRotForm.value.axialRotFemur) : 0;
				this.canvasSrv.dispatch('setFemurAxialRotation', axialRotFemur);
			}
			this.axialRotForm.controls.axialRotFemur.setValue(this.def_femur_axialRotation);
		}
	}

	/**
	* Update axial rotation sign of tibia.
	*/
	updateAxialRotSignTibia(sign: AxialRotationEnum): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			this.canvasSrv.dispatch('setTibiaAxialRotationSign', sign);
		}
	}

	/**
	* Update axial rotation value of tibia.
	*/
	updateAxialRotTibia(): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			if (this.axialRotForm.controls.axialRotTibia.valid) {
				const axialRotTibia = this.axialRotForm.value.axialRotTibia ? parseInt(this.axialRotForm.value.axialRotTibia) : 0;
				this.canvasSrv.dispatch('setTibiaAxialRotation', axialRotTibia);
			}
			this.axialRotForm.controls.axialRotTibia.setValue(this.def_tibia_axialRotation);
		}
	}

}
