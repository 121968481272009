<div class="spacing-top">
  <a class="wrapper-back" (click)="back()">
    <span class="material-icons md-18 back">arrow_back</span>
    <span class="action">{{ labels.TEMPLATE_SELECTION_COMPONENT_CANCEL_BTN }}</span>
  </a>
</div>

<div class="main-step">
  <div class="box-menu-settings active">

    <!--CORRECTION AND TEMPLATE SELECTION-->
    <div class="wrapper-select-accordion">

      <form [formGroup]="filtersForm">

        <div class="row-box">
          <div class="form-group">
            <label class="mb-0" for="AnatomicalSite">{{ labels.TEMPLATE_SELECTION_COMPONENT_ANATOMICAL_SITE}}</label>
            <div>{{ filtersForm.value.site || '-' }}</div>
          </div>
        </div>

        <div class="row-box pt-0">
          <div class="form-group">
            <label class="mb-0" for="Screw">{{ labels.TEMPLATE_SELECTION_COMPONENT_SCREW}}</label>
            <div class="form-group">
              <select class="form-control custom-select" id="Screw" formControlName="screw" (change)="updateScrew()">
                <option [ngValue]="null">-</option>
                <option *ngFor="let screw of prodSrv.screwList | async" [ngValue]="screw">{{screw}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row-box pt-0" *ngIf="filtersForm.value.site === siteFilterEnum.PROX_DIST_TIBIA">
          <div class="form-group">
            <label class="mb-0" for="Segment">{{ labels.TEMPLATE_SELECTION_COMPONENT_SEGMENT}}</label>
            <div class="form-group">
              <select class="form-control custom-select" id="Segment" formControlName="segment" (change)="updateSegment()">
                <option [ngValue]="null">-</option>
                <option *ngFor="let segment of prodSrv.segmentList | async" [ngValue]="segment">{{segment}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row-box pt-0" *ngIf="filtersForm.value.site === siteFilterEnum.PROXIMAL_FEMUR || filtersForm.value.site === siteFilterEnum.DISTAL_FEMUR">
          <div class="form-group">
            <label class="mb-0" for="Angle">{{ labels.TEMPLATE_SELECTION_COMPONENT_ANGLE}}</label>
            <div class="form-group">
              <select class="form-control custom-select" id="Angle" formControlName="angle" (change)="updateAngle()">
                <option [ngValue]="null">-</option>
                <option *ngFor="let angle of prodSrv.angleList | async" [ngValue]="angle">{{angle}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row-box pt-0" *ngIf="filtersForm.value.site === siteFilterEnum.PROXIMAL_FEMUR">
          <div class="form-group">
            <label class="mb-0" for="Offset">{{ labels.TEMPLATE_SELECTION_COMPONENT_OFFSET}}</label>
            <div class="form-group">
              <select class="form-control custom-select" id="Offset" formControlName="offset" (change)="updateOffset()">
                <option [ngValue]="null">-</option>
                <option *ngFor="let offset of prodSrv.offsetList | async" [ngValue]="offset">{{offset}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row-box pt-0" *ngIf="filtersForm.value.site === siteFilterEnum.DISTAL_FEMUR">
          <div class="form-group">
            <label class="mb-0" for="Flare">{{ labels.TEMPLATE_SELECTION_COMPONENT_FLARE}}</label>
            <div class="form-group">
              <select class="form-control custom-select" id="Flare" formControlName="flare" (change)="updateFlare()">
                <option [ngValue]="null">-</option>
                <option *ngFor="let flare of prodSrv.flareList | async" [ngValue]="flare">{{flare}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row-box pt-0">
          <div class="form-group">
            <label class="mb-0" for="Holes">{{ labels.TEMPLATE_SELECTION_COMPONENT_HOLES}}</label>
            <div class="form-group">
              <select class="form-control custom-select" id="Holes" formControlName="holes" (change)="updateHoles()">
                <option [ngValue]="null">-</option>
                <option *ngFor="let holes of prodSrv.holesList | async" [ngValue]="holes">{{holes}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row-box">
          <div class="box-suggestion">
            <p>
              {{ labels.TEMPLATE_SELECTION_COMPONENT_MESSAGE }}
            </p>
          </div>
        </div>
        <div class="row-box pt-0" *ngIf="this.filtersForm.value.screw">
          <div class="w-100">
            <h4 class="mb-3">{{ labels.TEMPLATE_SELECTION_COMPONENT_RESULTS }} &nbsp; {{ ($plateNameList | async)?.length }}</h4>
            <div class="result-filter" [ngClass]="{'loading': isAPPlateLoading || isLTPlateLoading }">
              <div *ngFor="let plateName of ($plateNameList | async); let i = index" (click)="insertPlate(i)">{{ plateName }}</div>
            </div>
          </div>
        </div>
        <div class="row-box">
          <div class="form-group">
            <label class="" for="plateOrientation">{{ labels.TEMPLATE_SELECTION_COMPONENT_PLATE_ORIENTATION }}</label>
            <div class="form-group">
              <div class="custom-control custom-radio custom-control-inline pt-0" (click)="setLateral()">
                <input type="radio" id="lateral" name="plateOrientation" class="custom-control-input"
                       [value]="plateOrientationType.lateral" formControlName="plateOrientation" />
                <label class="custom-control-label" for="lateral">{{ labels.TEMPLATE_SELECTION_COMPONENT_PLATE_LATERAL }}</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline pt-0" (click)="setMedial()">
                <input type="radio" id="medial" name="plateOrientation" class="custom-control-input"
                       [value]="plateOrientationType.medial" formControlName="plateOrientation" />
                <label class="custom-control-label" for="medial">{{ labels.TEMPLATE_SELECTION_COMPONENT_PLATE_MEDIAL }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row-box justify-content-end">
          <button type="button" class="btn btn-primary"
                  [disabled]="!isPlateInserted"
                  (click)="confirmTemplate()">
            {{ labels.TEMPLATE_SELECTION_COMPONENT_BUTTON}}
          </button>
        </div>

      </form>

    </div>



  </div>
</div>
