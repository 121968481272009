<!-- Top Nav -->
<div class="nav-top tlhex">
	<div class="nav-left-side" ngbDropdown container="body" placement="bottom-left">
		<span data-testid="SUBSYS4_NAVBAR_ACCOUNT_BTN" class="material-icons" ngbDropdownToggle>account_circle</span>
		<span data-testid="SUBSYS4_NAVBAR_USER_TXT" class="welcome-text">{{labels.NAVBAR_COMPONENT_WELCOME}} {{firstName}}
			{{lastName}}</span>

		<div class="dropdown-menu" ngbDropdownMenu>
			<a data-testid="SUBSYS4_NAVBAR_EULA_BTN" href="" onclick="return false;" class="dropdown-item"
				(click)="openLegalTerms()">
				{{ labels.NAVBAR_COMPONENT_EULA_POLICY }}
			</a>
			<a data-testid="SUBSYS4_NAVBAR_ABOUT_BTN" href="" onclick="return false;" class="dropdown-item"
				(click)="openAboutPage()">
				{{ labels.NAVBAR_COMPONENT_ABOUT }}
			</a>
			<div data-testid="SUBSYS4_NAVBAR_VERSION_TXT" class="simple-text" *ngIf="envName != 'production'">{{buildVersion}}
			</div>
			<a data-testid="SUBSYS4_NAVBAR_LOGOUT_BTN" href="" onclick="return false;" class="dropdown-item"
				(click)="logout()">
				{{ labels.NAVBAR_COMPONENT_LOGOUT }}
			</a>
		</div>
	</div>

	<div class="wrapper-info">
		<div data-testid="SUBSYS4_NAVBAR_PATID_TXT" class="box-info">
			<span>{{labels.NAVBAR_COMPONENT_PATIENT_ID}}</span> {{currentCase?.patient.number}}
		</div>
		<div data-testid="SUBSYS4_NAVBAR_PLANID_TXT" class="box-info">
			<span>{{labels.NAVBAR_COMPONENT_PLAN_ID}}</span> {{currentCase?.number}}
		</div>
		<div data-testid="SUBSYS4_NAVBAR_PLANSIDE_TXT" class="box-info">
			<span>{{labels.NAVBAR_COMPONENT_PLAN_SIDE}}</span> {{currentCase?.side}}
		</div>
		<div data-testid="SUBSYS4_NAVBAR_PLANBONE_TXT" class="box-info">
			<span>{{labels.NAVBAR_COMPONENT_PLAN_BONE_TYPE}}</span> {{currentCase?.boneType | boneTypeLabel}}
		</div>
		<div data-testid="SUBSYS4_NAVBAR_PLANSTEP_TXT" class="box-info">
			<span>{{labels.NAVBAR_COMPONENT_PLAN_STEP}}</span> {{planStepLabel}}
		</div>
		<div data-testid="SUBSYS4_NAVBAR_PLANTYPE_TXT" class="box-info">
			<span>{{labels.NAVBAR_COMPONENT_PLAN_TYPE}}</span> {{currentCase?.type}}
		</div>
	</div>
	<div class="nav-right-side position-static" [ngClass]="{ 'mobile': isMobile || isStrict }" ngbDropdown
		container="body" placement="bottom" (openChange)="getGuideList($event)">

		<span data-testid="SUBSYS4_NAVBAR_LOGO_TXT" class="logo-text"
			[class.logo-text-mobile]="isMobile || isStrict">{{labels.APP_LOGO_TEXT}}</span>

		<a data-testid="SUBSYS4_NAVBAR_SAVE_BTN" class="nav-top-link" href="" onclick="return false;"
			[ngClass]="{'disabled': isSaveDisabled }" (click)="save()" [title]="labels.NAVBAR_COMPONENT_SAVE"><i
				class="material-icons-outlined">save</i></a>

		<a data-testid="SUBSYS4_NAVBAR_GUIDE_BTN" class="nav-top-link" href="" onclick="return false;" ngbDropdownToggle
			[title]="labels.NAVBAR_COMPONENT_GUIDE">
			<img class="ifu-icon" alt="ifu icon">
		</a>

		<a data-testid="SUBSYS4_NAVBAR_HELP_BTN" class="nav-top-link" href="" onclick="return false;"
			(click)="openHelpModal()" [title]="labels.NAVBAR_COMPONENT_HELP"><i
				class="material-icons-outlined">live_help</i></a>
		<a data-testid="SUBSYS4_NAVBAR_BACK_BTN" class="nav-top-link btn btn-primary back"
			[ngClass]="{ 'back-mobile': isMobile || isStrict }" href="" onclick="return false;"
			(click)="openClosingModal()">{{ labels.NAVBAR_COMPONENT_BACK_BTN }}</a>

		<div class="dropdown-menu no-line" ngbDropdownMenu style="max-width: 30rem;">
			<a data-testid="SUBSYS4_NAVBAR_IFUSITE_BTN" [href]="eifuSiteUrl" target="_blank" class="dropdown-item">
				{{ labels.NAVBAR_COMPONENT_LINK_EIFU }}
			</a>
			<a href="" onclick="return false;" class="dropdown-item" *ngFor="let guide of guideList"
				(click)="openGuide(guide.path)">
				{{ labels[guide.labelKey] }}
			</a>
		</div>

	</div>
</div>