import { Component } from '@angular/core';
import { SelectedApexAnat } from '@ortho-next/nextray-core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';



/**
 * Table for anatomical axis measures in anatomical analysis.
 */
@Component({
	selector: 'aa-lt-table',
	templateUrl: './aa-lt-table.component.html'
})
export class AaLtTableComponent extends BaseComponent {

	isCollapsed: boolean = false;

	private readonly MAX_DEF = 90;
	private readonly MAX_TRANSL = 100;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	// VISIBILITY

	/**
	* Check if proximal table is visible in LT image.
	*/
	get isProxTabVisible(): boolean {
		return this.selectedApex !== SelectedApexAnat.distal;
	}

	/**
	* Check if distal table is visible in LT image.
	*/
	get isDistTabVisible(): boolean {
		return (this.isThirdLineInserted && !this.selectedApex) || this.selectedApex === SelectedApexAnat.distal;
	}

	// MEASURES
	/**
	* Get angular deformity of proximal apex in LT image.
	*/
	get ltProxAngDef(): string {
		return this.anatApexProxLT.def_angDef || this.anatApexProxLT.def_angDef === 0 ? `${this.anatApexProxLT.def_angDef} ${this.anatApexProxLT.def_angDefSign}` : '';
	}

	/**
	* Get lateral translation of proximal apex in LT image.
	*/
	get ltProxLatTransl(): string {
		return this.anatApexProxLT.def_transl || this.anatApexProxLT.def_transl === 0 ? `${this.anatApexProxLT.def_transl} ${this.anatApexProxLT.def_translSign}` : '';
	}

	/**
	* Get angular deformity of distal apex in LT image.
	*/
	get ltDistAngDef(): string {
		return this.anatApexDistLT.def_angDef || this.anatApexDistLT.def_angDef === 0 ? `${this.anatApexDistLT.def_angDef} ${this.anatApexDistLT.def_angDefSign}` : '';
	}

	/**
	* Get lateral translation of distal apex in LT image.
	*/
	get ltDistLatTransl(): string {
		return this.anatApexDistLT.def_transl || this.anatApexDistLT.def_transl === 0 ? `${this.anatApexDistLT.def_transl} ${this.anatApexDistLT.def_translSign}` : '';
	}

	// VALIDATION

	/**
	* Check if angular deformity of proximal apex in LT image is valid.
	*/
	get isValidLTProxAngDef(): boolean {
		return !this.anatApexProxLT?.def_angDef || this.anatApexProxLT.def_angDef <= this.MAX_DEF;
	}

	/**
	* Check if lateral translation of proximal apex in LT image is valid.
	*/
	get isValidLTProxLatTransl(): boolean {
		return !this.anatApexProxLT?.def_transl || this.anatApexProxLT.def_transl <= this.MAX_TRANSL;
	}

	/**
	* Check if angular deformity of distal apex in LT image is valid.
	*/
	get isValidLTDistAngDef(): boolean {
		return !this.anatApexDistLT?.def_angDef || this.anatApexDistLT.def_angDef <= this.MAX_DEF;
	}

	/**
	* Check if lateral translation of distal apex in LT image is valid.
	*/
	get isValidLTDistLatTransl(): boolean {
		return !this.anatApexDistLT?.def_transl || this.anatApexDistLT.def_transl <= this.MAX_TRANSL;
	}

}
