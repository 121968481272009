export const environment = {
	name: 'verification',
	production: true,
	insightKey: '5666fdb0-4bd1-494e-a92a-151101ab7cb3',
	domainPatientAPI: 'https://api-ver2.orthonext.app/pat/v1',
	domainUserAPI: 'https://api-ver2.orthonext.app/usr/v1',
	domainImageAPI: 'https://api-ver2.orthonext.app/img/v1',
	domainRayAPI: 'https://api-ver2.orthonext.app/ray/v1',
	domainTlhexAPI: 'https://api-ver2.orthonext.app/tlhex/v1',
	domainIntegrationAPI: 'https://api-ver2.orthonext.app/int/v1',
	sseAPI: 'https://api-ver2.orthonext.app/sse',
	cdnUrl: 'https://cdnver2.orthonext.app/ver',
	b2cTenantName: 'orthonextb2cver2',
	b2cTenantId: '262e6e85-9536-464a-bb73-0bb0c09a36e8',
	b2cSignInPolicyId: 'B2C_1_OnlySignIN2',
	b2cResetPasswordPolicyId: 'B2C_1_PasswordReset',
	b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
	b2cClientId: 'f3c6d7b4-e0ae-41eb-a7e9-17d08f2fab9e',
	b2cApplicationScope: 'openid profile https://orthonextb2cver2.onmicrosoft.com/api/user_impersonation',
	appSSOutUriApi: 'https://api-ver2.orthonext.app/services/appssouturi',
	idleTimeToEndSession: 1800,
	buildVer: 'OrthoNext-Ray FE VER CI-CD_24149.01',
	patplanSite: 'https://patver2.orthonext.app/list/plans',
	planDetailsSite: 'https://patver2.orthonext.app/plan/edit?planGuid=',
	legalTermsSite: 'https://requestver2.orthonext.app/legal-terms',
	aboutSite: 'https://cdnver2.orthonext.app/ver/about/about.html',
	guideSite: {
		baseUrl: 'https://cdnver2.orthonext.app/ver/Guide',
		list: 'https://cdnver2.orthonext.app/ver/Guide/guide_list.json'
	},
	helpSite: {
		baseUrl: 'https://cdnver2.orthonext.app/ver/Help/NextRay20/html',
		fileNames: 'https://cdnver2.orthonext.app/ver/Help/NextRay20/file_names.json'
	},
	eifuSite: 'https://ifu.orthofix.it',
	templatesSite: 'https://fitbonever2.orthonext.app',
	tlhexRaySite: 'https://tlhexver2.orthonext.app/hexray',
	rayModelVersion: '1.1',
	resetSubscriptionTimeout: 30000,
	cookieName: '_iub_cs-67063076'
};
