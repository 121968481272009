import { Component } from '@angular/core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Component for anatomical deformity tables of preop plan print. 
 */
@Component({
	selector: 'prt-aa-def-tables',
	templateUrl: './prt-aa-def-tables.component.html'
})
export class PrtAaDefTablesComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	/**
	* Get angular deformity of proximal apex in AP image.
	*/
	get apProxAngDef(): string {
		return this.anatApexProxAP.def_angDef || this.anatApexProxAP.def_angDef === 0 ? `${this.anatApexProxAP.def_angDef} ${this.anatApexProxAP.def_angDefSign}` : '';
	}

	/**
	* Get lateral translation of proximal apex in AP image.
	*/
	get apProxLatTransl(): string {
		return this.anatApexProxAP.def_transl || this.anatApexProxAP.def_transl === 0 ? `${this.anatApexProxAP.def_transl} ${this.anatApexProxAP.def_translSign}` : '';
	}

	/**
	* Get angular deformity of proximal apex in LT image.
	*/
	get ltProxAngDef(): string {
		return this.anatApexProxLT.def_angDef || this.anatApexProxLT.def_angDef === 0 ? `${this.anatApexProxLT.def_angDef} ${this.anatApexProxLT.def_angDefSign}` : '';
	}

	/**
	* Get lateral translation of proximal apex in LT image.
	*/
	get ltProxLatTransl(): string {
		return this.anatApexProxLT.def_transl || this.anatApexProxLT.def_transl === 0 ? `${this.anatApexProxLT.def_transl} ${this.anatApexProxLT.def_translSign}` : '';
	}

	/**
	* Get axial translation in AP image.
	*/
	get apAxialTranslMeasure(): string {
		return this.anatApexProxAP.def_ax_transl || this.anatApexProxAP.def_ax_transl === 0 ? `${this.anatApexProxAP.def_ax_transl} ${this.anatApexProxAP.def_ax_translSign}` : '';
	}

	/**
	* Get axial translation in LT image.
	*/
	get ltAxialTranslMeasure(): string {
		return this.anatApexProxLT.def_ax_transl || this.anatApexProxLT.def_ax_transl === 0 ? `${this.anatApexProxLT.def_ax_transl} ${this.anatApexProxLT.def_ax_translSign}` : '';
	}

}
