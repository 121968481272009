<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.LLD_TABLE_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<table aria-label="LLD measures" class="table" [ngbCollapse]="isCollapsed">
		<thead>
			<tr>
				<th></th>
				<th>{{ labels.LLD_TABLE_LENGTH_HEADER }} <br /> ({{ isLeft ? sideEnum.Left : sideEnum.Right }})</th>
				<th>{{ labels.LLD_TABLE_CL_LENGTH_HEADER }} <br /> ({{ isLeft ? sideEnum.Right : sideEnum.Left }})</th>
				<th>{{ labels.LLD_TABLE_LLD_HEADER }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_TABLE_LONGLEG }}</td>
				<td [highlightCell]="defAPMeasures?.def_ap_fullLength">{{ defAPMeasures?.def_ap_fullLength }}</td>
				<td [highlightCell]="defCLMeasures?.def_ap_fullLength_ctrlateral">
					{{ defCLMeasures?.def_ap_fullLength_ctrlateral }}</td>
				<td [highlightCell]="longlegLLD">{{ longlegLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg || isFemur">
				<td>{{ labels.LLD_TABLE_FEMUR }}</td>
				<td [highlightCell]="defAPMeasures?.def_ap_femurLength">{{ defAPMeasures?.def_ap_femurLength }}</td>
				<td [highlightCell]="defCLMeasures?.def_ap_femurLength_ctrlateral">
					{{ defCLMeasures?.def_ap_femurLength_ctrlateral }}</td>
				<td [highlightCell]="femurLLD">{{ femurLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg || isTibia">
				<td>{{ labels.LLD_TABLE_TIBIA }}</td>
				<td [highlightCell]="defAPMeasures?.def_ap_tibiaLength">{{ defAPMeasures?.def_ap_tibiaLength }}</td>
				<td [highlightCell]="defCLMeasures?.def_ap_tibiaLength_ctrlateral">
					{{ defCLMeasures?.def_ap_tibiaLength_ctrlateral }}</td>
				<td [highlightCell]="tibiaLLD">{{ tibiaLLD }}</td>
			</tr>
			<tr *ngIf="isLongLeg">
				<td>{{ labels.LLD_TABLE_FEMUR_TIBIA }}</td>
				<td [highlightCell]="trueLength">{{ trueLength }}</td>
				<td [highlightCell]="trueLengthCL">{{ trueLengthCL }}</td>
				<td [highlightCell]="trueLengthLLD">{{ trueLengthLLD }}</td>
			</tr>
		</tbody>
	</table>
</div>