import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthCoreModule } from '@ortho-next/auth-core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { APP_ROUTES } from './app.routes';
import { FallbackComponent, LanguageService, LoaderService, MonitoringErrorHandler, MonitoringService } from './core';
import { ErrorsComponent } from './core/components/errors/errors.component';
import { CaseRepository, ImageRepository, IntegrationRepository, ModelRepository, ProductRepository, TlhexRepository, UserRepository } from './core/repositories';
import { PrintModule } from './print/print.module';
import { CanvasService, CaseService, HelpService, HexrayMapperService, ImageService, MainCanvasLoaderService, ModelService, PrinterService, ProductService, TlhexService, UserService } from './services';
import { TemplateModule } from './template';


export function initApp(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.loadLabels();
	};
};


@NgModule({
	declarations: [
		AppComponent,
		ErrorsComponent,
		FallbackComponent
	],
	imports: [
		FormsModule,
		BrowserModule,
		AuthCoreModule.forRoot(AUTH_CONFIG),
		RouterModule.forRoot(APP_ROUTES),
		CommonModule,
		ReactiveFormsModule,
		TemplateModule,
		HttpClientModule,
		PrintModule
	],
	providers: [
		CaseRepository,
		ImageRepository,
		CaseService,
		ImageService,
		MainCanvasLoaderService,
		CanvasService,
		ProductRepository,
		ProductService,
		CookieService,
		MonitoringService,
		PrinterService,
		LoaderService,
		UserService,
		UserRepository,
		ModelService,
		ModelRepository,
		HelpService,
		TlhexRepository,
		TlhexService,
		IntegrationRepository,
		HexrayMapperService,
		{
			provide: ErrorHandler,
			useClass: MonitoringErrorHandler
		},
		LanguageService,
		{
			provide: APP_INITIALIZER, useFactory: initApp, deps: [LanguageService], multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
