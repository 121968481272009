import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ViewType } from '@ortho-next/nextray-core';
import { OverAngulationSign, OverTranslSign } from '../../../../nextray/Models/Enums';
import { SetAnatEoCMeasure } from '../../../../nextray/States/State';
import { Consts } from '../../../../nextray/Utils/Consts';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';



/**
 * Table for anatomical axis measures in correction anatomical analysis.
 */
@Component({
	selector: 'corr-aa-lt-table',
	templateUrl: './corr-aa-lt-table.component.html'
})
export class CorrAaLtTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = false;

	// ANGULATION
	overAngForm: FormGroup;
	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d?$/;
	private readonly MIN_VALUE = 0;
	private readonly MAX_VALUE = 90;
	varusLbl: string;
	valgusLbl: string;
	overAngEnum = OverAngulationSign;
	invalidOverAngMsg: string;

	// TRANSLATION
	overTranslForm: FormGroup;
	private readonly threeDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;
	private readonly MAX_TRANSL = 100;
	lateralLbl: string;
	medialLbl: string;
	overTranslEnum = OverTranslSign;
	invalidOverTranslMsg: string;


	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.initOverAngForm();
		this.initOverTranslForm();
		this.initLabels();
		this.initInvalidMsgs();
	}

	private initLabels(): void {
		const boneType = this.canvasSrv.currentCase.boneType;
		this.valgusLbl = Consts.signLabels[boneType][ViewType[ViewType.LT]].rotation[0];
		this.varusLbl = Consts.signLabels[boneType][ViewType[ViewType.LT]].rotation[1];
		this.medialLbl = Consts.signLabels[boneType][ViewType[ViewType.LT]].translation[0];
		this.lateralLbl = Consts.signLabels[boneType][ViewType[ViewType.LT]].translation[1];
	}

	private initInvalidMsgs(): void {
		const labelOA: string = this.labels['CORR_AA_LT_TABLE_COMPONENT_OVER_ANGULATION_WNG'];
		this.invalidOverAngMsg = labelOA?.replace("%1", this.MIN_VALUE.toString())?.replace("%2", this.MAX_VALUE.toString());
		const labelOT: string = this.labels['CORR_AA_LT_TABLE_COMPONENT_OVER_TRANSLATION_WNG'];
		this.invalidOverTranslMsg = labelOT?.replace("%1", this.MIN_VALUE.toString())?.replace("%2", this.MAX_TRANSL.toString());
	}


	// OVER ANGULATION

	private initOverAngForm(): void {
		const validators: ValidatorFn[] = [Validators.pattern(this.twoDigitsPattern), Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)];
		this.overAngForm = new FormGroup({
			'overAngValue': new FormControl(this.overAngulation, validators),
			'overAngSign': new FormControl(this.overAngulationSign)
		});
	}

	/**
	 * Get current over angulation.
	 */
	get overAngulation(): number {
		return this.eocMeasuresAnat?.eoc_lt_overAng ?? 0;
	}

	/**
	 * Get current over angulation sign.
	 */
	get overAngulationSign(): OverAngulationSign {
		return this.eocMeasuresAnat?.eoc_lt_overAngSign ? OverAngulationSign[this.eocMeasuresAnat.eoc_lt_overAngSign] : OverAngulationSign.valgus;
	}

	/**
	 * Check if over angulation is valid.
	 */
	get isOverAngValid(): boolean {
		return this.overAngForm.controls.overAngValue.valid;
	}

	/**
	 * Check if over angulation form is disabled.
	 */
	get isOverAngDisabled(): boolean {
		return this.stateType !== this.stateTypeEnum.EOC || !this.isEocCut;
	}

	/**
	 * Decrement over angulation value. 
	 */
	decrementOverAng(): void {
		if (!this.isOverAngDisabled) {
			const val = Number.parseInt(this.overAngForm.value.overAngValue);
			const sign = this.overAngForm.value.overAngSign as OverAngulationSign;
			if (!Number.isNaN(val) && val > this.MIN_VALUE) {
				this.setOverAng(val - 1, sign);
			}
		}
	}

	/**
	 * Increment over angulation value. 
	 */
	incrementOverAng(): void {
		if (!this.isOverAngDisabled) {
			const val = Number.parseInt(this.overAngForm.value.overAngValue);
			const sign = this.overAngForm.value.overAngSign as OverAngulationSign;
			if (!Number.isNaN(val) && val < this.MAX_VALUE) {
				this.setOverAng(val + 1, sign);
			}
		}
	}

	/**
	 * Update over angulation value. 
	 */
	updateOverAng(): void {
		if (!this.isOverAngDisabled) {
			const val = Number.parseInt(this.overAngForm.value.overAngValue);
			const sign = this.overAngForm.value.overAngSign as OverAngulationSign;
			if (!Number.isNaN(val)) {
				this.setOverAng(val, sign);
			}
		}
	}

	/**
	* Update over angulation sign.
	*/
	updateOverAngSign(sign: OverAngulationSign): void {
		if (!this.isOverAngDisabled) {
			this.setOverAng(this.overAngulation, sign);
		}
	}

	private setOverAng(val: number, sign: OverAngulationSign): void {
		const data: SetAnatEoCMeasure<OverAngulationSign> = {
			view: ViewType.LT,
			value: val,
			sign: sign
		};
		this.canvasSrv.dispatch('setOverAngulation', data);
	}


	// OVER TRANSLATION

	private initOverTranslForm(): void {
		const validators: ValidatorFn[] = [Validators.pattern(this.threeDigitsPattern), Validators.min(this.MIN_VALUE), Validators.max(this.MAX_TRANSL)];
		this.overTranslForm = new FormGroup({
			'overTranslValue': new FormControl(this.overTranslation, validators),
			'overTranslSign': new FormControl(this.overTranslationSign)
		});
	}

	/**
	 * Get current over translation.
	 */
	get overTranslation(): number {
		return this.eocMeasuresAnat?.eoc_lt_overTransl ?? 0;
	}

	/**
	 * Get current over translation sign.
	 */
	get overTranslationSign(): OverTranslSign {
		return this.eocMeasuresAnat?.eoc_lt_overTranslSign ? OverTranslSign[this.eocMeasuresAnat.eoc_lt_overTranslSign] : OverTranslSign.medial;
	}

	/**
	 * Check if over translation is valid.
	 */
	get isOverTranslValid(): boolean {
		return this.overTranslForm.controls.overTranslValue.valid;
	}

	/**
	 * Check if over translation form is disabled.
	 */
	get isOverTranslDisabled(): boolean {
		return this.stateType !== this.stateTypeEnum.EOC || !this.isEocCut;
	}

	/**
	 * Decrement over translation value. 
	 */
	decrementOverTransl(): void {
		if (!this.isOverTranslDisabled) {
			const val = Number.parseInt(this.overTranslForm.value.overTranslValue);
			const sign = this.overTranslForm.value.overTranslSign as OverTranslSign;
			if (!Number.isNaN(val) && val > this.MIN_VALUE) {
				this.setOverTransl(val - 1, sign);
			}
		}
	}

	/**
	 * Increment over translation value. 
	 */
	incrementOverTransl(): void {
		if (!this.isOverTranslDisabled) {
			const val = Number.parseInt(this.overTranslForm.value.overTranslValue);
			const sign = this.overTranslForm.value.overTranslSign as OverTranslSign;
			if (!Number.isNaN(val) && val < this.MAX_TRANSL) {
				this.setOverTransl(val + 1, sign);
			}
		}
	}

	/**
	 * Update over translation value. 
	 */
	updateOverTransl(): void {
		if (!this.isOverTranslDisabled) {
			const val = Number.parseInt(this.overTranslForm.value.overTranslValue);
			const sign = this.overTranslForm.value.overTranslSign as OverTranslSign;
			if (!Number.isNaN(val)) {
				this.setOverTransl(val, sign);
			}
		}
	}

	/**
	* Update over translation sign.
	*/
	updateOverTranslSign(sign: OverTranslSign): void {
		if (!this.isOverTranslDisabled) {
			this.setOverTransl(this.overTranslation, sign);
		}
	}

	private setOverTransl(val: number, sign: OverTranslSign): void {
		const data: SetAnatEoCMeasure<OverTranslSign> = {
			view: ViewType.LT,
			value: val,
			sign: sign
		};
		this.canvasSrv.dispatch('setOverTransl', data);
	}

}
