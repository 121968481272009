import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BridgeResultMessagesBase, ViewType } from '@ortho-next/nextray-core';
import { environment } from '../../environments/environment';
import { BridgeResultMessages } from '../../nextray/Core/Bridge';
import { LanguageService, LoaderService, ToastService } from '../core';
import { CanvasService, CaseService, MainCanvasLoaderService, ProductService, UserService } from '../services';
import { BaseComponent, ConfirmationComponent } from '../shared';


/**
* Canvas view component
*/
@Component({
	selector: 'canvas3d',
	templateUrl: './canvas3d.component.html'
})
export class Canvas3DComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

	isJPSExtension: boolean;

	private _isPltDemo: boolean;
	private _isJPSDemo: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private mainCanvLoadSrv: MainCanvasLoaderService,
		private toastSrv: ToastService,
		private userSrv: UserService,
		private modalSrv: NgbModal,
		private prodSrv: ProductService,
		private caseSrv: CaseService,
		private loaderSrv: LoaderService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.userSrv.isPlatformDemo().subscribe(res => this._isPltDemo = res);
		this.userSrv.isJPSDemoProduct().subscribe(res => this._isJPSDemo = res);
		this.userSrv.isJPSExtension().subscribe(res => this.isJPSExtension = res);
		this.initCanvas();
	}

	ngAfterViewInit(): void {
		this.canvasSrv.addEventListener('onResult', this.osteotomyLengthError);
		this.canvasSrv.addEventListener('onResult', this.jpsUpgrading);
	}

	private initCanvas(): void {
		this.loaderSrv.show();
		this.mainCanvLoadSrv.init().subscribe({
			next: () => this.canvasSrv.addEventListener('onResult', this.imagesLoadedEvent),
			error: (err: Error) => {
				this.loaderSrv.hide();
				this.handlePlanError(err);
			}
		});
	}

	/**
	* Event listener for osteotomy length error.
	*/
	osteotomyLengthError = (event) => {
		if (event.args === BridgeResultMessages.osteotomyLengthError) {
			this.toastSrv.showWarning(this.labels['CANVAS3D_COMPONENT_OSTEOTOMY_LENGTH_ERROR']);
		}
	}

	/**
	* Event listener for JPS upgrading.
	*/
	jpsUpgrading = (event) => {
		if (event.args === BridgeResultMessagesBase.restore) {
			if (this.isJPSExtension && this.isPlateInserted && !this.hasJPSExtended) {
				this.openUpgradeJPSModal();
			}
			else {
				this.prodSrv.initJPSExtended(this.isJPSExtension);
			}
		}
	}

	/**
	 * Watermark Demo
	 */
	get isDemo(): boolean {
		return this.stateType === this.stateTypeEnum.templating ? this._isJPSDemo : this._isPltDemo;
	}

	/**
	* Check if up/bottom label is visible
	*/
	get isUpBottomLabelVisible(): boolean {
		return this.isForeFoot || this.isHindFoot;
	}

	/**
	* Check if magnifier is visible
	*/
	get isMagnifierVisible(): boolean {
		return /*!this.isMobile &&*/ (this.stateDescription?.isWorkflowRunning == this.workflowEnum.insertMechanicalAxis || this.stateDescription?.isWorkflowRunning == this.workflowEnum.insertContralateral) && (this.activeViewState != this.activeViewStateEnum.multiple) && this.stateDescription.isPointsPositioningRunning;
	}

	/**
	* Check if is Ap view active in multiple state
	*/
	get isMultipleAP(): boolean {
		return this.activeViewState === this.activeViewStateEnum.multiple && this.activeView === this.activeViewEnum.AP;
	}

	/**
	* Check if reference measure warning is visible
	*/
	get isRefMeasureWarningVisible(): boolean {
		return this.isFracture && this.isAnatomicalAxisInserted && this.anatAxialTranslRef == null;
	}

	/**
	* Check if anatomical warning is visible
	*/
	get isAnatomicalWarningVisible(): boolean {
		return (this.deformityAnalysisType === this.defTypeEnum.anatomical && this.isAnatomicalAxisInserted && !this.isEocCut) && (this.isApexTooFar || this.isOstTooFar);
	}

	/**
	* Get anatomical warning message
	*/
	get anatomicalWarningMsg(): string {
		const apexMsg = this.isApexTooFar;
		const ostMsg = this.isOstTooFar;
		if (apexMsg && ostMsg) return this.labels['CANVAS3D_COMPONENT_ANATOMICAL_APEX_OST_WARNING'];
		else if (apexMsg) return this.labels['CANVAS3D_COMPONENT_ANATOMICAL_APEX_WARNING'];
		else if (ostMsg && !this.isPostOp) return this.labels['CANVAS3D_COMPONENT_ANATOMICAL_OST_PREOP_WARNING'];
		else if (ostMsg && this.isPostOp) return this.labels['CANVAS3D_COMPONENT_ANATOMICAL_OST_POSTOP_WARNING'];
		else return null;
	}

	private get isApexTooFar(): boolean {
		return this.isDeformity && (this.activeView === ViewType.AP ? this.apexTooFarAP : this.apexTooFarLT);
	}

	private get isOstTooFar(): boolean {
		return this.activeView === ViewType.AP ? this.ostTooFarAP : this.ostTooFarLT;
	}

	/**
	* Check if anatomical axis sync feedabck is visible
	*/
	get isAnatSyncFeedbackVisible(): boolean {
		return this.stateType === this.stateTypeEnum.deformityAnalysis && this.deformityAnalysisType === this.defTypeEnum.anatomical;
	}

	/**
	* Fit to view the current active view
	*/
	fitToView(): void {
		this.canvasSrv.dispatch("fitToView");
	}

	/**
	* Zoom in the current active view
	*/
	zoomIn(): void {
		this.canvasSrv.dispatch("zoomIn");
	}

	/**
	* Zoom out the current active view
	*/
	zoomOut(): void {
		this.canvasSrv.dispatch("zoomOut");
	}

	private handlePlanError(err: Error): void {
		switch (err.message) {
			case 'PlanNotFound': {
				this.openInvalidPlanModal(this.labels['PLAN_DELETED_WARNING_MESSAGE']);
				break;
			}
			case 'PlanNotValid': {
				this.openInvalidPlanModal(this.labels['INVALID_PLAN_MODAL_MESSAGE']);
				break;
			}
			case 'WebglDisabled': {
				this.openInvalidPlanModal(this.labels['WEBGL_INVALID_WARNING_MESSAGE']);
				break;
			}
			case 'ScreenResolutionInvalid': {
				this.openInvalidPlanModal(this.labels['RESOLUTION_INVALID_WARNING_MESSAGE']);
				break;
			}
			default:
				throw err;
		}
	}

	private openInvalidPlanModal(message: string): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.langSrv.getLabels()['INVALID_PLAN_MODAL_TITLE'],
			message,
			confirmBtn: this.langSrv.getLabels()['INVALID_PLAN_MODAL_CONFIRM_BTN'],
			isCancelBtn: false
		};
		modalRef.result.then(() => window.location.href = environment.patplanSite);
	}

	private openUpgradeJPSModal(): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.langSrv.getLabels()['UPGRADE_JPS_MODAL_TITLE'],
			message: this.langSrv.getLabels()['UPGRADE_JPS_MODAL_MESSAGE'],
			confirmBtn: this.langSrv.getLabels()['UPGRADE_JPS_MODAL_CONFIRM_BTN'],
			cancelBtn: this.langSrv.getLabels()['UPGRADE_JPS_MODAL_CANCEL_BTN']
		};
		modalRef.result.then(
			() => this.upgradeJPS(true),
			() => this.upgradeJPS(false),
		);
	}
	private upgradeJPS(upgrade: boolean): void {
		if (upgrade) {
			this.prodSrv.initJPSExtended(true);
			const plateId = this.plate.id;
			this.prodSrv.getScrewList(plateId, true).subscribe(list => this.canvasSrv.dispatch("updateScrews", list));
		} else {
			this.caseSrv.setReadOnly();
			this.prodSrv.initJPSExtended(false);
		}
	}

	/**
	* Event listener for update after images loaded.
	*/
	private imagesLoadedEvent = (event) => {
		if (event.args == BridgeResultMessagesBase.imagesLoaded) {
			this.loaderSrv.hide();
		}
	};

	ngOnDestroy() {
		this.canvasSrv.removeEventListener('onResult', this.osteotomyLengthError);
		this.canvasSrv.removeEventListener('onResult', this.imagesLoadedEvent);
	}

}
