import { Pipe, PipeTransform } from '@angular/core';
import { BoneTypeEnum, LanguageService } from '../../core';


/**
 * Pipe to display Bone Type enum
 */
@Pipe({
	name: 'boneTypeLabel'
})
export class BoneTypeLabelPipe implements PipeTransform {

	constructor(private langSrv: LanguageService) { }

	transform(boneType: BoneTypeEnum): string {
		return boneType == BoneTypeEnum.LongLeg ? this.langSrv.getLabels()["BONE_TYPE_LONG_LEG_LABEL"] : boneType;
	}
}
