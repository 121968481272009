import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { ProductTypeEnum, UserProfile, UserRepository } from '../core';
import { BaseService } from './base.service';



export const CUSTOMER_CARE: string = 'CustomerCare';

/**
* This service handles user profile data
*/
@Injectable()
export class UserService extends BaseService {

	private _currentUser$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);

	constructor(private userRepo: UserRepository) {
		super();
	}

	/**
	* Get user first name and last name.
	*/
	getUserName(): Observable<{ firstName: string, lastName: string }> {
		return this.getUserProfile().pipe(filter(user => !!user), map(user => ({ firstName: user.firstName, lastName: user.lastName })));
	}

	/**
	* Get user country code.
	*/
	getUserCountry(): Observable<string> {
		return this.getCurrentUser().pipe(map(user => user.country.code));
	}

	/**
	* Get current user.
	*/
	getCurrentUser(): Observable<UserProfile> {
		return this._currentUser$.asObservable().pipe(first(user => !!user));
	}

	/**
	* Check if user has a JPS product
	*/
	isJPSProduct(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			filter(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.JPS))
		);
	}

	/**
	* Check if user has a JPS DEMO product
	*/
	isJPSDemoProduct(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			filter(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.JPS && p.demo))
		);
	}

	/**
	* Check if user has a Fitbone product
	*/
	isFitboneProduct(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			filter(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.Fitbone))
		);
	}

	/**
	* Check if user has Platform DEMO product
	*/
	isPlatformDemo(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			filter(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.Platform && p.demo))
		);
	}

	/**
	* Check if user is JPS Extension
	*/
	isJPSExtension(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			filter(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.JPS && p.version == "1.1"))
		);
	}

	/**
	* Check if user has a TLHEX product
	*/
	isTlhexProduct(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			filter(user => !!user && !!user.products),
			map(user => user.products.some(p => p.type == ProductTypeEnum.TLHex))
		);
	}

	/**
	* Check if user is Customer Care
	*/
	isCustomerCare(): Observable<boolean> {
		return this._currentUser$.asObservable().pipe(
			first(user => !!user && !!user.roles),
			map(user => user.roles.includes(CUSTOMER_CARE))
		);
	}


	private getUserProfile(): Observable<UserProfile> {
		return this.userRepo.getUserProfile().pipe(
			map(res => this.handleApiResponse(res)),
			tap(user => this._currentUser$.next(user))
		);
	}

}
